import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router"
import { useDispatch } from "react-redux";
import {  Navbar } from "react-bootstrap";

import logoImg from "../../assets/images/logo.png";


import './navbar.scss'
const navItems = [
  { name: "Investor Feed", path: "dashboard" },
  { name: "Explore", path: "explore" },
  { name: "Conversations", path: "conversations" },
];

const Navigationbar = () => {
  const searchRef = useRef(null)
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(location.pathname);

 
  const [searchResults, setSearchResults] = useState({
    isLoading: false,
    hasError: false,
    errorMsg: ""
  });
  const [data, setData] = useState({
    searchTerm: "",
    searchData: [],
    selectedData: [],
    filteredData: [],
  });

  const [extended, setExtended] = useState(false);
  
  return (
    <>
      <Navbar
        expand="lg"
        expanded={extended}
        style={{ background: "#fff" }}
        className={extended ? "extended navbar_" : "navbar_"}
      >
        <Navbar.Brand
          onClick={() => {
            navigate("/explore");
            setActiveTab("/explore");
          }}
        >
          <span className="cursor-pointer m-r-20 m-l-50">
            <img src={logoImg} alt="Logo" />
          </span>
        </Navbar.Brand>
      </Navbar>
    </>
  );
};

export { Navigationbar };
