import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { ReactComponent as FiltersAddedIcon } from "../../../assets/images/icon_svg/filters-added-icon.svg";
import { ReactComponent as FiltersChangedIcon } from "../../../assets/images/icon_svg/filters-changed-icon.svg";
import { ReactComponent as FiltersRemovedIcon } from "../../../assets/images/icon_svg/filters-removed-icon.svg";
import './searchhistoryslider.scss';

const SearchHistorySlider = ({ isOpen, onClose, searchFilterApplied, callbackFn }) => {
  const [tpOwners] = useSelector(({ discoveryReducer }) => [discoveryReducer.tpOwners]);
  console.log("Search Applied", searchFilterApplied);
  const determineFilterChanges = (prevFilters, currentFilters) => {
    const changes = {
      added: {},
      changed: {},
      removed: {},
    };
  
    Object.keys(currentFilters.filters || {}).forEach((key) => {
      const currentValues = currentFilters.filters[key] || [];
      const prevValues = prevFilters.filters?.[key] || [];
  
      const addedValues = currentValues.filter(value => !prevValues.includes(value));
      const removedValues = prevValues.filter(value => !currentValues.includes(value));
  
      // Handle added, removed, and changed filters
      if (addedValues.length && removedValues.length) {
        changes.changed[key] = addedValues;
      } 
      else if (addedValues.length) {
        changes.added[key] = addedValues;
      }
      else if (removedValues.length) {
        changes.removed[key] = removedValues;
      }
    });
  
    Object.keys(prevFilters.filters || {}).forEach((key) => {
      if (!(key in currentFilters.filters)) {
        const removedValues = prevFilters.filters[key];
        changes.removed[key] = removedValues;
      }
    });
  
    return changes;
  };

  const renderFilterSections = (filters, sectionTitle, sectionClass, icon = null, totalCount) => {
    if (!Object.keys(filters).length ) return null;
    return (
      <div className={`filters-section ${sectionClass}`}>
        <div className="filters-header">
          {icon && <span className="filters-icon">{icon}</span>}
          {sectionTitle}
        </div>
        {totalCount !== undefined && (
          <div className="filter-total-count">
            <i>Total Count:</i> <i className='total-count-value'>{totalCount ?? 0}</i>
          </div>
        )}
        <div className="filters-content">
          {Object.entries(filters).map(([key, values]) => (
            <div key={key} className="filter-group">
              <div className="filter-name">
                <span className="filter-key">{key}:</span>
              </div>
              {renderFilterValues(key, values, sectionClass)}
            </div>
          ))}
        </div>
      </div>
    );
  };
  const removeFilter = (key, value) => {
    callbackFn(key, value);
    onClose();
  }
  const findRemovedFilters = (filterObjects) => {
    if (filterObjects.length < 2) return [];

    const lastFilters = filterObjects[filterObjects.length - 1]?.filters || {};

    return filterObjects.slice(0, -1).reduce((removedStrings, prev) => {
      const prevFilters = prev.filters || {};

      Object.keys(prevFilters).forEach(key => {
        if (lastFilters[key]) {
          // Find values that exist in prevFilters but not in lastFilters
          const removedValues = prevFilters[key].filter(value => !lastFilters[key].includes(value));
          removedStrings.push(...removedValues.map(value => `${value}`));
        } else {
          // Entire key is removed
          removedStrings.push(...prevFilters[key].map(value => `${value}`));
        }
      });

      return removedStrings;
    }, []);
  };
  const renderFilterValues = (key, values, sectionClass) => {
    if (!Array.isArray(values) || !values.length) return null;
    return (
      <div className="filter-values">
        {values.map((value, idx) => {
          let compare = findRemovedFilters(searchFilterApplied);
          const tpOwner = tpOwners.find(owner => owner.email_address === value);
          const displayValue = tpOwner ? tpOwner.full_name : value;
          const removeIcon = sectionClass === 'filters-removed' || compare?.includes(value) ? null : <span className="remove-filter">x</span>;
          return (
            <span key={`${key}-${idx}`} className="filter-value" >
              {displayValue} <span onClick={() => removeFilter(key, value)}>{removeIcon}</span>
            </span>
          );
        })}
      </div>
    );
  };

  const renderFilters = () => {
    return searchFilterApplied.map((filters, index) => {
      const prevFilters = index > 0 ? searchFilterApplied[index - 1] : {};
      const changes = determineFilterChanges(prevFilters, filters);

      const hasAdded = Object.keys(changes.added).length > 0;
      const hasChanged = Object.keys(changes.changed).length > 0;
      const hasRemoved = Object.keys(changes.removed).length > 0;
      
      return (
        <div key={index} className="search-history-block">
          <div className="time-icon-line"></div>
          <div className="icon-container">
            <span className="time-line-shape-square">&#x25A0;</span>
          </div>
          {hasAdded &&
            renderFilterSections(
              changes.added,
              'Filter Added',
              'filters-added',
              <FiltersAddedIcon />,
              filters.totalCount
            )}
          {hasChanged &&
            renderFilterSections(
              changes.changed,
              'Filter Changed',
              'filters-changed',
              <FiltersChangedIcon />,
              filters.totalCount
            )}
          {hasRemoved &&
            renderFilterSections(
              changes.removed,
              'Filter Removed',
              'filters-removed',
              <FiltersRemovedIcon />,
              filters.totalCount
            )}
          <div className="icon-container1">
            <span className="time-line-shape-triangle">&#x25BE;</span>
          </div>
        </div>
      );
    });
  };

  return (
    <Offcanvas show={isOpen} onHide={onClose} placement="end" style={{ width: '40pc' }} className="search-history-slider">
      <Offcanvas.Header closeButton style={{ marginTop: '10px' }}></Offcanvas.Header>
      <Offcanvas.Body>
        <Offcanvas.Title className="search-history-title">Search details</Offcanvas.Title>
        {searchFilterApplied && searchFilterApplied.length > 0 ? (
          renderFilters()
        ) : (
          <div className="no-history">No search history available.</div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SearchHistorySlider;
