import React,{ useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {Container } from "react-bootstrap"
import { Outlet} from 'react-router-dom'
import {Navigationbar} from "../../commonComponents/Navbar";
import { StatsigProvider } from "statsig-react";

import { getLoggedInUser } from "../../store/actions";
import { axiosUserInfoInstance } from '../../config/appConfig/axiosInstance';


// import Sidepanel from "../../commonComponents/SidePanel";
import SideMenu from "../../commonComponents/SideMenu/SideMenu";
import "./parentContainer.scss"

const ParentContainer = () => {
  const statsigKey = process.env.statsig_key;
  const dispatch = useDispatch();
  const location = useLocation();
  const [userInfo] = useSelector(({ dashBoardReducer }) => [dashBoardReducer?.userInfo])
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState({userID: '', email: ""});

  const statsig_env = process.env.npm_config_env === "PROD" ? "production" : "development"
  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

   useEffect(() => {
    if(!userInfo) dispatch(getLoggedInUser(axiosUserInfoInstance));
  }, [])
  useEffect(()=> {
    if(Object?.keys(userInfo || {})?.length) {
      setUser({userID: userInfo?.email, email: userInfo?.email})
    }
  },[userInfo])

  return (
    <>
     <StatsigProvider sdkKey={statsigKey} waitForInitialization={false}
        options={{
          environment: { tier: statsig_env },
        }}
        user={user}
        setUser={setUser}
      >
      <Navigationbar/>
      <Container fluid className={`main-box ${isSidebarOpen ? 'sidebar-open' : ''} ${location?.pathname.includes('/explore') ?  "greyBackground" : ""} `} >
        {/* <div className='sidebar-background' onClick={handleToggleSidebar}></div> */}
        <div className="sidebar-panel">
          <SideMenu isSidebarOpen={isSidebarOpen} toggleSidebar={handleToggleSidebar} />
          {/* <Sidepanel isSidebarOpen={isSidebarOpen} toggleSidebar={handleToggleSidebar} /> */}
        </div>
        <div className={`content-box ${isSidebarOpen ? 'extended' : ''}`}><Outlet /></div>
      </Container>
      </StatsigProvider>
    </>
  );
};

export default ParentContainer ;
