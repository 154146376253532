import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Row, Col, Form, Dropdown} from "react-bootstrap";
import { getAddress } from "../../utils";
import axios from "axios";
import * as actionTypes from "../../store/actionTypes";
import * as amplitude from '@amplitude/analytics-browser';
import {
  getTpStatusDiscoveryOptions,
  getDiscoveryCompanies,
  getDiscoveryTpOwners,
  getDiscoveryFiltersMetaData,
  getDiscoverySearchHistory
} from "../../store/actions";
import {
  axiosCompanyPageTpStatusOptions,
  axiosGetWorkboardPeople,
  axiosDiscoveryFiltersData,
  axiosLLMFilters,
  axiosDiscoverySearchHistory
} from "../../config/appConfig/axiosInstance";
import Nofilter from "../../commonComponents/NoFilter/NoFilter";
import PlainTootip from "../../commonComponents/InfoTooltip/PlainTooltip";
import { setDiscoveryFiltersPayload, getLLMFiltersData, setAppliedFilters } from "../../store/actions/discoveryAction";
import DiscoveryCards from "./DiscoveryCards/DiscoveryCards";
import CommonError from "../../commonComponents/CommonError/CommonError";
import TableLoader from "./TableLoader/TableLoader";

import {
  totalFundingConstans,
  employeeInsightConstants,
  emailConstants,
  infoMessage,
  founderLocationTags,
  totalFundingInfoIcon
} from "./discoveryConstant";
import { tagsInDays } from "../../utils";
import TagsInSlider from "./TagsInSlider/TagsInSlider";
import LocationFilter from "./LocationFilter/LocationFilter";
import FounderLocation from "./FounderLocation/FounderLocation";

import { ReactComponent as ArrowUpSvg } from "../../assets/images/icon_svg/arrows-up.svg";
import { ReactComponent as ArrowDownSvg } from "../../assets/images/icon_svg/arrows-down.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icon_svg/search_icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icon_svg/X.svg";
import { ReactComponent as EyeIcon } from "../../assets/images/icon_svg/eye-icon.svg";
import { ReactComponent as ToggleArrowLeft } from "../../assets/images/icon_svg/double-arrow-left-icon.svg";
import { ReactComponent as ToggleArrowRight } from "../../assets/images/icon_svg/double-arrow-right-icon.svg";
import { ReactComponent as LoaderIcon } from '../../assets/images/icon_svg/loader-icon.svg';
import { ReactComponent as FiltersIcon } from '../../assets/images/icon_svg/filters_logo.svg';
import { ReactComponent as SortByIcon } from '../../assets/images/icon_svg/sort-by.svg';
import { ReactComponent as PromptIcon } from '../../assets/images/icon_svg/prompt-icon.svg';
import { ReactComponent as TelescopeSearchIcon } from '../../assets/images/icon_svg/telescope-search.svg';
import { ReactComponent as StarsIcon } from '../../assets/images/icon_svg/stars-icon.svg';
import { ReactComponent as MoonIcon } from '../../assets/images/icon_svg/moon-icon.svg';
import { ReactComponent as DustbinIcon } from '../../assets/images/icon_svg/dustbin-icon.svg';
import { ReactComponent as CollapseIcon } from '../../assets/images/icon_svg/collapse-icon.svg';
import { ReactComponent as ExpandIcon } from '../../assets/images/icon_svg/expand-icon.svg';
import earthLoader from "../../assets/images/discovery-loader.gif";
import "./discovery.scss";
import InputFields from "./InputFields/InputFields";
import SearchHistorySlider from "./SearchHistorySlider/SearchHistorySlider";
import Slider from "./Slider/Slider";

const column_order = [
  "tp_owners",
  "tp_status",
  "growth",
  "growth_tags",
  "year_founded",
  "employee_insights",
  "investment_data",
  "visit_history",
  "communication_with_telescope",
  "founder_location_search",
  "headquarter_search",
  "status",
];
let cancelToken;
let llmInputFilterClicked = false;

const result = column_order.map((col) => {
  let displayName = col
    .replace(/_/g, " ")
    .replace(/\b\w/g, (l) => l.toUpperCase());

  if (col === "tp_owners" || col === "tp_status") {
    displayName = displayName.replace("Tp", "TP");
  }

  if (col === "communication_with_telescope") {
    displayName = displayName.replace("With", "with");
  }

  if (col === "headquarter_search") {
    displayName = "Headquarter Search";
  }

  return {
    display_name: displayName,
    server_value: col,
    infoMessage: infoMessage?.[`${col}`] ? infoMessage[col] : ""
  };
});

const fieldsWithSearchBox = ["tp_owners", "tp_status", "status"];
const preDaysTags = ["total_funding_usd"];
const employeeInsightsTags = ["employee_count"];
const growthTags = [
  { value: 'growth_6m', displayName: 'Growth 6m' },
  { value: 'growth_1y', displayName: 'Growth 1y' },
  { value: 'growth_2y', displayName: 'Growth 2y' }
];

const Discovery = () => {

  const dispatch = useDispatch();
  const filtersRef = useRef(null);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const toggleBtnRef = useRef(null);
  const [
    tpStatusOptions,
    tpOwners,
    countries,
    states,
    cities,
    status,
    llmFilterData,
    growth_tags,
    discoveryCompaniesResults, 
    searchFilterApplied
  ] = useSelector(({ discoveryReducer }) => [
    discoveryReducer.discoveryTpStatusOptions.data,
    discoveryReducer.tpOwners,
    discoveryReducer.metadata.countries,
    discoveryReducer.metadata.states,
    discoveryReducer.metadata.cities,
    discoveryReducer.metadata.statuses,
    discoveryReducer?.llmFilterData,
    discoveryReducer.metadata.growth_tags,
    discoveryReducer.discoveryCompanies,
    discoveryReducer.searchFilterApplied
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [dropdownsearchTerms, setDropdownSearchTerms] = useState({});
  const [checkboxObject, setCheckboxObject] = useState({});
  const [checkboxObjectVisiblity, setCheckboxObjectVisiblity] = useState({});
  const [tpStatusOperator, setTpStatusOperator] = useState("is");
  const [tpStatusSince, setTpStatusSince] = useState("");
  const [sliderObject, setSliderObject] = useState({});
  const [preTags, setPreTags] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [openItems, setOpenItems] = useState([]);
  const [accordionObject, setAccordionObject] = useState({});
  const [currentSelection, setCurrentSelection] = useState("");
  const [llmFounderLocation, setLLmFounderLocation] = useState({});
  const [populateLocation, setPopulateLocation] = useState([]);
  const [selectedGrowthsData, setSelectedGrowthsData] = useState([]);
  const [hqLocation, setHqLocation] = useState({});
  const [selectedTotalFundingData, setSelectedTotalFundingData] = useState({ trueFalse: 'false' });
  const [filterSortState, setFilterSortState] = useState({
    sortCriteria: "",
    filterTpStatus: null,
    filterTpOwner: null,
  });
  const [filteredLocationObject, setFilteredLocationObject] = useState({
    keys: "",
    value: "",
  });
  const [yearFounded, setYearFounded] = useState({
    greaterThanValue: '',
    lesserThanValue: ''
  });
  // const [countriesData, setCountriesData] = useState([]);
  // const [filterCountry, setFilterCountry] = useState("");
  const [founderLocationTag, setFounderLocationTag] = useState({
    displayName: "Countries",
    serverValue: "countries"
  });
  const [selectedFounderLocation, setSelectedFounderLocation] = useState({
    type: "",
    data: []
  });
  const [metaDataLoading, setMetaDataLoading] = useState({
    isLoading: false,
    errorMessage: "",
  });
  const [founderLocationChange, setFounderLocationChange] = useState("")
  const [communicationFilter, setCommunicationFilter] = useState({
    option: "",
    reachedOut: false,
    notReachedOutValue: "",
    timeUnit: "days",
    days_of_email_sent: 0,
  });
  const [visitHistoryFilter, setVisitHistoryFilter] = useState({
    visitHistoryValue: "",
    timeUnit: "days",
    days_of_email_sent: 0,
  });
  const [llmFilter, setLLMFilters] = useState({
    enteredValue: "",
    searchTerm: "Show all companies"
  })
  const [historySlider, setHistorySlider] = useState(false)
  const [searchHistory, setSearchHistory] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [hasResults, setHasResults] = useState(true);
  const [appliedFilterString, setAppliedFilterString] = useState("");
  const [growthsData, setGrowthsData] = useState([]);
  const [selectedGrowthsTag, setSelectedGrowthsTag] = useState([]);
  const [isActiveBtn, setIsActiveBtn] = useState("orion");
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [target, setTarget] = useState(null);
  const [activeFilter, setActiveFilter] = useState("tp_owners");
  const [saasFilter, setSaasFilter] = useState({
    isSaasFilterApplied: false,
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false); 

  useEffect(() => {
    setMetaDataLoading({
      ...metaDataLoading,
      isLoading: true,
    });
    dispatch(getDiscoveryFiltersMetaData(axiosDiscoveryFiltersData)).then(
      (res) => {
        if (res.status === "success") {
          setMetaDataLoading({
            ...metaDataLoading,
            isLoading: false,
            errorMessage: "",
          });
          amplitude.logEvent(`Discovery Loaded`);
        } else {
          setMetaDataLoading({
            ...metaDataLoading,
            isLoading: false,
            errorMessage: "No metadata available!",
          });
        }
      }
    );
    dispatch(getTpStatusDiscoveryOptions(axiosCompanyPageTpStatusOptions));
    dispatch(getDiscoveryTpOwners(axiosGetWorkboardPeople));
  }, []);

  useEffect(() => {
    if (column_order?.length) {
      let finalObject = {};
      column_order?.forEach((i, inx) => {
        finalObject[i] = {
          name: i,
          index: inx,
          isAccordionOpen: false,
        };
      });
      setAccordionObject(finalObject);
    }
  }, [column_order]);

  useEffect(() => {
    if (fieldsWithSearchBox?.length) {
      let finalObject = {};
      let visbilityObject = {};
      let searchObject = {};
      fieldsWithSearchBox?.map((i) => {
        if (i === "tp_status") {
          finalObject[i] = [];
        } else {
          finalObject[i] = [];
        }
        visbilityObject[i] = {
          count: 20,
          visible: false,
          searchCount: 0,
          isSearching: false,
        };
        searchObject[i] = "";
      });
      setCheckboxObject(finalObject);
      setCheckboxObjectVisiblity(visbilityObject);
      setDropdownSearchTerms(searchObject);
    }
  }, [fieldsWithSearchBox]);

  useEffect(() => {
    if (preDaysTags?.length) {
      let finalObject = {};
      preDaysTags?.map((i) => {
        finalObject[i] = [];
      });
      setPreTags(finalObject);
    }
  }, [preDaysTags]);

  useEffect(() => {
    if (growth_tags?.length) {
      let formattedGrowth = growth_tags?.map(i => {
        let displayName = i?.tag_name?.replace(/_/g, ' ')?.replace(/\b\w/g, (l) => l.toUpperCase());
        return {
          displayName: displayName,
          value: i.tag_name,
          count: i.tag_count,
          description: i.tag_description
        }
      })
      setGrowthsData(formattedGrowth)
    }
  }, [growth_tags])

  useEffect(() => {
    if (column_order?.length) {
      const filteredColumnOrder = column_order.filter(
        (item) =>
          !fieldsWithSearchBox.includes(item) || !preDaysTags.includes(item)
      );

      const finalObject = {};
      filteredColumnOrder?.forEach(
        (i) =>
        (finalObject[i] = {
          greaterThanThumbPosition: null,
          lesserThanThumbPosition: null,
          symbol:
            i === "employee_count"
              ? ""
              : i === "sales_engineering_percentage" ||
                i === "seniority_percentage"
                ? "%"
                : "days",
          maxValue:
            i === "sales_engineering_percentage" ||
              i === "seniority_percentage"
              ? 100
              : ["employee_count"].includes(i)
                ? 10000
                : 100,
        })
      );
      employeeInsightsTags.forEach((tag) => {
        finalObject[tag] = {
          greaterThanThumbPosition: null,
          lesserThanThumbPosition: null,
          symbol: tag === "employee_count" ? "" : "%",
          maxValue: tag === "employee_count" ? 10000 : 100,
        };
      });

      // Exclude year_founded from finalObject
      const sliderObjectKeys = Object.keys(finalObject).filter(key => key !== 'year_founded');
      const newSliderObject = {};
      sliderObjectKeys.forEach(key => {
        newSliderObject[key] = finalObject[key];
      });

      setSliderObject(newSliderObject);
    }
  }, [column_order]);

  useEffect(() => {
    if (llmFilter?.enteredValue) {
      setIsLoading(true);
      handleResetAll();
      dispatch(getLLMFiltersData(axiosLLMFilters, { filter_search: llmFilter?.enteredValue }))
    } else {
      setIsLoading(false);
    }
  }, [llmFilter?.enteredValue]);

  useEffect(() => {
    if (llmFilterData && Object.keys(llmFilterData)?.length && !isEmptyObject()) {
      populateFilterFn();
    } else {
      setIsLoading(false);
      setHasResults(false);
    }
  }, [llmFilterData]);

  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.RESET_LLM_FILTERS
      })
      handleResetAll();
      setLLMFilters({
        enteredValue: "",
        searchTerm: ""
      })
    }
  }, []);

  const isEmptyObject = () => {
    let hasEmpty = Object.entries(llmFilterData).every(([key, value]) => {
      if (key === 'description') {
        return true;
      }
      if (key === 'uuid') {
        return true;
      } else if (Array.isArray(value)) {
        return value.length === 0;
      } else if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length === 0;
      } else {
        return value === "n/a" || value === null || value === 0 || value === "";
      }
    });
    return hasEmpty;
  }

  const debounce = (delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        handleSubmit();
      }, delay);
    };
  };

  const debouncedHandleSubmit = debounce(handleSubmit, 300);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleSubmit();
    }, 300);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [
    checkboxObject,
    sliderObject,
    preTags,
    selectedGrowthsData,
    selectedGrowthsTag,
    selectedTotalFundingData,
    hqLocation,
    // countriesData,
    yearFounded,
    tpStatusSince,
    selectedFounderLocation,
    communicationFilter.reachedOut,
    communicationFilter.option,
    communicationFilter.days_of_email_sent,
    communicationFilter.notReachedOutValue,
    visitHistoryFilter.visitHistoryValue,
    visitHistoryFilter.days_of_email_sent,
    saasFilter?.isSaasFilterApplied
  ]);

  useEffect(() => {
    if (checkboxObject["tp_status"]?.length) debouncedHandleSubmit();
  }, [tpStatusOperator]);

  const handleSearchBoxChange = (field, value, array) => {
    setDropdownSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      [field]: value,
    }));

    if (value) {
      setCheckboxObjectVisiblity((prevCheckboxObject) => {
        // Copy the previous state to avoid mutating it directly
        const updatedObject = { ...prevCheckboxObject };
        // Check if serverValue exists in the checkboxObject
        if (updatedObject.hasOwnProperty(field)) {
          updatedObject[field]["isSearching"] = true;
          updatedObject[field]["searchCount"] =
            field === "tp_owners"
              ? array?.filter((item) =>
                item?.full_name?.toLowerCase()?.includes(value?.toLowerCase())
              )?.length
              : array?.filter((item) =>
                item?.toLowerCase()?.includes(value?.toLowerCase())
              )?.length;
        }
        return updatedObject;
      });
    } else {
      setCheckboxObjectVisiblity((prevCheckboxObject) => {
        // Copy the previous state to avoid mutating it directly
        const updatedObject = { ...prevCheckboxObject };
        // Check if serverValue exists in the checkboxObject
        if (updatedObject.hasOwnProperty(field)) {
          updatedObject[field]["isSearching"] = false;
          updatedObject[field]["searchCount"] = 0;
        }
        return updatedObject;
      });
    }
  };

  const toggleFilters = (event) => {
    setIsFiltersVisible((prev) => !prev);
    setTarget(event.target);
  };

  const handleClickOutside = (event) => {
    if (filtersRef.current &&
      !filtersRef.current.contains(event.target) &&
      toggleBtnRef.current &&
      !toggleBtnRef.current.contains(event.target)) {
      setIsFiltersVisible(false);
    }
  };

  useEffect(() => {

    if (isFiltersVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFiltersVisible]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const processSliderData = (field, item, setSliderObject) => {
    let itemArr = item.value.split(":");
    let newState = {
      greaterThanValue: "",
      lessThanValue: "",
      selectedTags: []
    };

    if (itemArr[0].includes("GREATER_THAN_OR_EQUAL_TO")) {
      newState.greaterThanValue = itemArr[1];
      newState.selectedTags.push({
        value: itemArr[1],
        operator: "greaterThan"
      });
    }
    if (itemArr[0].includes("LESS_THAN_OR_EQUAL_TO")) {
      newState.lessThanValue = itemArr[1];
      newState.selectedTags.push({
        value: itemArr[1],
        operator: "lessThan"
      });
    }

    setSliderObject(prevState => {
      let combinedSelectedTags = [
        ...prevState[field]?.selectedTags?.filter(tag => {
          return !newState.selectedTags.some(newTag => newTag.operator === tag.operator);
        }) || [],
        ...newState.selectedTags
      ];
      const updatedFieldData = {
        ...prevState[field],
        greaterThanValue: newState.greaterThanValue || prevState[field]?.greaterThanValue || "",
        lessThanValue: newState.lessThanValue || prevState[field]?.lessThanValue || "",
        selectedTags: combinedSelectedTags
      };
      return {
        ...prevState,
        [field]: updatedFieldData
      };
    });
  };

  const populateFilterFn = () => {
    let updatedAccordionObject = { ...accordionObject };

    if (Object.keys(llmFilterData)?.length) {
      Object.keys(llmFilterData)?.forEach(i => {
        if (i === "tp_owner" && llmFilterData[i]?.length) {
          let selectedTP_Owners = tpOwners?.filter(tpOwner => llmFilterData[i].includes(tpOwner.email_address));
          setCheckboxObject(prevState => ({
            ...prevState,
            tp_owners: selectedTP_Owners.map(owner => owner.email_address)
          }));
          if (updatedAccordionObject["tp_owners"]) {
            updatedAccordionObject["tp_owners"].isAccordionOpen = true;
          }
        }
        if (i === "tp_status_value" && llmFilterData[i]?.length) {
          setCheckboxObject(prevState => ({
            ...prevState,
            tp_status: llmFilterData[i]
          }));
          if (llmFilterData["tp_status_operator"]) {
            setTpStatusOperator(llmFilterData["tp_status_operator"].toLowerCase());
          }
          if (llmFilterData["tp_status_since"]) {
            setTpStatusSince(llmFilterData["tp_status_since"]);
          }
          if (updatedAccordionObject["tp_status"]) {
            updatedAccordionObject["tp_status"].isAccordionOpen = true;
          }
        }

        if (i === "status" && llmFilterData[i]?.length) {
          setCheckboxObject(prevState => ({
            ...prevState,
            status: llmFilterData[i]
          }));
          if (updatedAccordionObject["status"]) {
            updatedAccordionObject["status"].isAccordionOpen = true;
          }
        }

        if (i === "founder_city" && llmFilterData[i]?.length) {
          setFounderLocationTag({
            displayName: "Cities",
            serverValue: "cities"
          });
          setLLmFounderLocation({
            type: 'cities',
            val: llmFilterData[i]
          });
          if (updatedAccordionObject["founder_location_search"]) {
            updatedAccordionObject["founder_location_search"].isAccordionOpen = true;
          }
          setCurrentSelection("founder_location_search");
          const founderFilterElement = document.querySelector(
            '.filter-name[data-filter="founder_location_search"]'
          );
          if (founderFilterElement) {
            founderFilterElement.click();
          }
        }

        if (i === "founder_country" && llmFilterData[i]?.length) {
          setFounderLocationTag({
            displayName: "Countries",
            serverValue: "countries"
          });
          setLLmFounderLocation({
            type: 'countries',
            val: llmFilterData[i]
          });
          if (updatedAccordionObject["founder_location_search"]) {
            updatedAccordionObject["founder_location_search"].isAccordionOpen = true;
          }
          setCurrentSelection("founder_location_search");
          const founderFilterElement = document.querySelector(
            '.filter-name[data-filter="founder_location_search"]'
          );
          if (founderFilterElement) {
            founderFilterElement.click();
          }
        }

        if (i === "founder_state" && llmFilterData[i]?.length) {
          setFounderLocationTag({
            displayName: "States",
            serverValue: "states"
          });
          setLLmFounderLocation({
            type: 'states',
            val: llmFilterData[i]
          });
          if (updatedAccordionObject["founder_location_search"]) {
            updatedAccordionObject["founder_location_search"].isAccordionOpen = true;
          }
          setCurrentSelection("founder_location_search");
          const founderFilterElement = document.querySelector(
            '.filter-name[data-filter="founder_location_search"]'
          );
          if (founderFilterElement) {
            founderFilterElement.click();
          }
        }

        if (i === "hq_city" && llmFilterData[i]?.length) {
          setPopulateLocation(prevState => [
            ...prevState,
            { type: 'cities', val: llmFilterData[i].map(word => word[0].toUpperCase() + word.slice(1)) }
          ]);
          if (updatedAccordionObject["headquarter_search"]) {
            updatedAccordionObject["headquarter_search"].isAccordionOpen = true;
          }
          setCurrentSelection("headquarter_search");
          const founderFilterElement = document.querySelector(
            '.filter-name[data-filter="headquarter_search"]'
          );
          if (founderFilterElement) {
            founderFilterElement.click();
          }
        }

        if (i === "hq_state" && llmFilterData[i]?.length) {
          setPopulateLocation(prevState => [
            ...prevState,
            { type: 'states', val: llmFilterData[i].map(word => word[0].toUpperCase() + word.slice(1)) }
          ]);
          if (updatedAccordionObject["headquarter_search"]) {
            updatedAccordionObject["headquarter_search"].isAccordionOpen = true;
          }
          setCurrentSelection("headquarter_search");
          const founderFilterElement = document.querySelector(
            '.filter-name[data-filter="headquarter_search"]'
          );
          if (founderFilterElement) {
            founderFilterElement.click();
          }
        }

        if (i === "hq_country" && llmFilterData[i]?.length) {
          setPopulateLocation(prevState => [
            ...prevState,
            { type: 'countries', val: [llmFilterData[i][0].toUpperCase() + llmFilterData[i].slice(1)] }
          ]);
          if (updatedAccordionObject["headquarter_search"]) {
            updatedAccordionObject["headquarter_search"].isAccordionOpen = true;
          }
          setCurrentSelection("headquarter_search");
          const founderFilterElement = document.querySelector(
            '.filter-name[data-filter="headquarter_search"]'
          );
          if (founderFilterElement) {
            founderFilterElement.click();
          }
        }

        if (i === "query_filters" && llmFilterData[i]?.length) {
          llmFilterData["query_filters"]?.forEach(item => {
            if (item?.field === "year_founded") {
              let itemArr = item?.value?.split(":");
              let newState = {};

              if (itemArr[0]?.includes("GREATER")) {
                newState.greaterThanValue = itemArr[1];
              }
              if (itemArr[0]?.includes("LESS")) {
                newState.lesserThanValue = itemArr[1];
              }

              setYearFounded(prevState => ({
                ...prevState,
                ...newState
              }));
              if (updatedAccordionObject["year_founded"]) {
                updatedAccordionObject["year_founded"].isAccordionOpen = true;
              }
            }

            if (item?.field.includes("growth")) {
              const growthTag = growthTags.find(tag => item.field.includes(tag.value));
              if (growthTag) {
                let itemArr = item.value.split(":");
                let newState = {
                  displayName: growthTag.displayName,
                  value: growthTag.value,
                  greaterThanValue: "",
                  lessThanValue: ""
                };

                if (itemArr[0].includes("GREATER")) {
                  newState.greaterThanValue = itemArr[1];
                }
                if (itemArr[0].includes("LESS")) {
                  newState.lessThanValue = itemArr[1];
                }

                setSelectedGrowthsData(prevState => {
                  const existingIndex = prevState.findIndex(growth => growth.value === growthTag.value);
                  if (existingIndex !== -1) {
                    const updatedGrowth = {
                      ...prevState[existingIndex],
                      greaterThanValue: newState.greaterThanValue || prevState[existingIndex].greaterThanValue,
                      lessThanValue: newState.lessThanValue || prevState[existingIndex].lessThanValue
                    };
                    return [
                      ...prevState.slice(0, existingIndex),
                      updatedGrowth,
                      ...prevState.slice(existingIndex + 1)
                    ];
                  } else {
                    return [
                      ...prevState,
                      newState
                    ];
                  }
                });
                if (updatedAccordionObject["growth"]) {
                  updatedAccordionObject["growth"].isAccordionOpen = true;
                }
              }
            }

            if (item?.field === "employee_count") {
              processSliderData("employee_count", item, setSliderObject);
              if (updatedAccordionObject["employee_insights"]) {
                updatedAccordionObject["employee_insights"].isAccordionOpen = true;
              }
            }

            if (item?.field === "total_funding_usd") {
              processSliderData("investment_data", item, setSliderObject);
              if (updatedAccordionObject["investment_data"]) {
                updatedAccordionObject["investment_data"].isAccordionOpen = true;
              }
            }
          });
        }

        if (i === "days_of_email_sent" && llmFilterData[i] !== null) {
          setCommunicationFilter(prevState => ({
            ...prevState,
            days_of_email_sent: llmFilterData[i],
            option: "reached_out",
            reachedOut: true,
            notReachedOutValue: llmFilterData[i],
            timeUnit: "days"
          }));
          if (updatedAccordionObject["communication_with_telescope"]) {
            updatedAccordionObject["communication_with_telescope"].isAccordionOpen = true;
          }
        }

        if (i === "has_email_sent" && (llmFilterData[i] === true || llmFilterData[i] === false)) {
          if (llmFilterData[i] === true) {
            setCommunicationFilter(prevState => ({
              ...prevState,
              option: "reached_out",
              reachedOut: true,
              notReachedOutValue: llmFilterData["days_of_email_sent"] || "",
              days_of_email_sent: llmFilterData["days_of_email_sent"] || 0,
              timeUnit: "days"
            }));
          } else if (llmFilterData[i] === false) {
            setCommunicationFilter(prevState => ({
              ...prevState,
              option: "not_reached_out",
              reachedOut: false,
              notReachedOutValue: llmFilterData["days_of_email_sent"] || "",
              days_of_email_sent: llmFilterData["days_of_email_sent"] || 0,
              timeUnit: "days"
            }));
          }
          if (updatedAccordionObject["communication_with_telescope"]) {
            updatedAccordionObject["communication_with_telescope"].isAccordionOpen = true;
          }
        }
      });
    }

    const founderLocationFields = ["founder_city", "founder_state", "founder_country"];
    const headquarterLocationFields = ["hq_city", "hq_state", "hq_country"];
    if (founderLocationFields.every(field => (llmFilterData[field]?.length === 0))) {
      setCurrentSelection("");
    } else if (headquarterLocationFields.every(field => (llmFilterData[field]?.length === 0))) {
      setCurrentSelection("");
    }

    setAccordionObject(updatedAccordionObject);
  };

  const getFiltersPayload = () => {
    let params = "", filteredObject = {};
    // check check box
    if (checkboxObject["tp_owners"]?.length) {
      params =
        `${params && params + "&"}` +
        checkboxObject["tp_owners"]
          .map((value) => `tp_owner_email=${value}`)
          .join("&");

        filteredObject[`TP Owner`] = checkboxObject["tp_owners"]
    }
    if (checkboxObject["tp_status"]?.length) {
      params =
        `${params && params + "&"}` + `tp_status_operator=${tpStatusOperator}`;
      params =
        `${params && params + "&"}` +
        checkboxObject["tp_status"]
          ?.map((value) => `tp_status_value=${value?.toLowerCase()}`)
          .join("&");

      filteredObject[`TP Status`] = checkboxObject["tp_status"]
      if (tpStatusOperator === "was" && tpStatusSince) {
        params += `${params && "&"}tp_status_since=${tpStatusSince}`;
      }
    }
    // if (countriesData?.length) {
    //   params =
    //     `${params && params + "&"}` +
    //     countriesData?.map((value) => `country=${value}`).join("&");
    // }

    if (hqLocation["countries"]?.selected?.length) {
      params =
        `${params && params + "&"}` +
        hqLocation["countries"]?.selected
          ?.map((value) => `hq_country=${value}`)
          .join("&");
      filteredObject["Headquarter Countries"] = hqLocation["countries"].selected;
    }
    if (hqLocation["states"]?.selected?.length) {
      params =
        `${params && params + "&"}` +
        hqLocation["states"]?.selected
          ?.map((value) => `hq_state=${value}`)
          .join("&");
        filteredObject["Headquarter States"] = hqLocation["states"].selected;
    }
    if (hqLocation["cities"]?.selected?.length) {
      params =
        `${params && params + "&"}` +
        hqLocation["cities"]?.selected
          ?.map((value) => `hq_city=${value}`)
          .join("&");
      filteredObject["Headquarter Cities"] = hqLocation["cities"].selected;
    }

    if (checkboxObject["status"]?.length) {
      params =
        `${params && params + "&"}` +
        checkboxObject["status"]?.map((value) => `status=${value}`).join("&");
        filteredObject[`Status`] = checkboxObject["status"]
    }
    const employeeCount = sliderObject["employee_count"];
    if (employeeCount) {
      if (employeeCount.selectedTags?.length) {
        if (!filteredObject['Employee Insights']) {
          filteredObject['Employee Insights'] = [];
        }
        employeeCount.selectedTags?.forEach((tag) => {
          if (tag?.operator === "greaterThan" && tag?.value) {
            params += `${params ? "&" : ""
              }employee_count=GREATER_THAN_OR_EQUAL_TO:${tag.value}`;
            filteredObject['Employee Insights'].push(`>= ${tag.value}`);
          } else if (tag?.operator === "lessThan" && tag?.value) {
            params += `${params ? "&" : ""
              }employee_count=LESS_THAN_OR_EQUAL_TO:${tag.value}`;
            filteredObject['Employee Insights'].push(`<= ${tag.value}`);
          } else if (
            tag?.minCount !== undefined &&
            tag?.maxCount !== undefined
          ) {
            params += `${params ? "&" : ""
              }employee_count=GREATER_THAN_OR_EQUAL_TO:${tag.minCount}`;
            params += `&employee_count=LESS_THAN_OR_EQUAL_TO:${tag.maxCount}`;
            filteredObject['Employee Insights'].push(
              `${tag.minCount} - ${tag.maxCount}`
            );
          } else if (tag?.value === "below_20") {
            params += `${params ? "&" : ""
              }employee_count=LESS_THAN_OR_EQUAL_TO:20`;
            filteredObject['Employee Insights'].push(`<= 20`);
          } else if (tag?.value === "above_1000") {
            params += `${params ? "&" : ""
              }employee_count=GREATER_THAN_OR_EQUAL_TO:1000`;
            filteredObject['Employee Insights'].push(`>= 1000`);
          }
        });
      }
    }
    const investmentData = sliderObject["investment_data"];
    if (investmentData) {
      if (investmentData.selectedTags?.length) {
        if (!filteredObject['Investment Data']) {
          filteredObject['Investment Data'] = [];
        }
        investmentData.selectedTags.forEach((tag) => {
          if (tag?.operator === "greaterThan" && tag?.value) {
            params += `${params ? "&" : ""}total_funding_usd=GREATER_THAN_OR_EQUAL_TO:${tag.value}`;
            filteredObject['Investment Data'].push(`>= ${tag.value}`);
          } else if (tag?.operator === "lessThan" && tag?.value) {
            params += `${params ? "&" : ""}total_funding_usd=LESS_THAN_OR_EQUAL_TO:${tag.value}`;
            filteredObject['Investment Data'].push(`<= ${tag.value}`);
          }
        });
        if (selectedTotalFundingData.trueFalse) {
          params += `${params && "&"}total_funding_usd_no_data=${selectedTotalFundingData.trueFalse}`;
        }
      }
    }
    // Growth
    selectedGrowthsData.forEach((selectedTag) => {
      const growthPeriod = selectedTag.value.split('_')[1];
      let growthFilter = '';

      if (selectedTag.greaterThanValue) {
        params += `${params && "&"}` + `${selectedTag.value}=GREATER_THAN_OR_EQUAL_TO:${selectedTag.greaterThanValue}`;
        growthFilter = `${growthPeriod}: >= ${selectedTag.greaterThanValue}%`;
        if (!filteredObject['Growth']) {
          filteredObject['Growth'] = [];
        }
        filteredObject['Growth'].push(growthFilter);
      }
      if (selectedTag.lessThanValue) {
        params += `${params && "&"}` + `${selectedTag.value}=LESS_THAN_OR_EQUAL_TO:${selectedTag.lessThanValue}`;
        growthFilter = `${growthPeriod}: <= ${selectedTag.lessThanValue}%`;
        if (!filteredObject['Growth']) {
          filteredObject['Growth'] = [];
        }
        filteredObject['Growth'].push(growthFilter);
      }
    });
    if (selectedGrowthsTag?.length) {
      params = `${params && params + "&"}` + selectedGrowthsTag?.map(i => `growth_tag=${i?.value}`).join('&');
      if (!filteredObject['Growth Tags']) {
        filteredObject['Growth Tags'] = [];
      }
      selectedGrowthsTag.forEach(tag => {
        if (tag?.displayName) {
          filteredObject['Growth Tags'].push(tag.displayName);
        }
      });
    }
    // Year Founded
    if (yearFounded.greaterThanValue || yearFounded.lesserThanValue) {
      if (!filteredObject['Year Founded']) {
        filteredObject['Year Founded'] = [];
      }
      if (yearFounded.greaterThanValue) {
        params +=
          `${params && "&"}` +
          `year_founded=GREATER_THAN_OR_EQUAL_TO:${yearFounded.greaterThanValue}`;
        filteredObject['Year Founded'].push(`>= ${yearFounded.greaterThanValue}`);
      }
      if (yearFounded.lesserThanValue) {
        params +=
          `${params && "&"}` +
          `year_founded=LESS_THAN_OR_EQUAL_TO:${yearFounded.lesserThanValue}`;
        filteredObject['Year Founded'].push(`<= ${yearFounded.lesserThanValue}`);
      }
    }
    // Founder Location
    if (selectedFounderLocation?.type && selectedFounderLocation?.data?.length > 0) {
      if (!filteredObject["Founder Location"]) {
        filteredObject["Founder Location"] = [];
      }
      if (selectedFounderLocation?.type === "countries") {
        params += `${params && "&"}` + selectedFounderLocation.data.map(country => `founder_country=${country}`).join("&");
        filteredObject["Founder Countries"] = selectedFounderLocation?.data
      }
      if (selectedFounderLocation?.type === "states") {
        params += `${params && "&"}` + selectedFounderLocation.data.map(state => `founder_state=${state}`).join("&");
        filteredObject["Founder States"] = selectedFounderLocation?.data
      }
      if (selectedFounderLocation?.type === "cities") {
        params += `${params && "&"}` + selectedFounderLocation.data.map(city => `founder_city=${city}`).join("&");
        filteredObject["Founder Cities"] = selectedFounderLocation?.data
      }
    }
    // Is SaaS Filter
    if (saasFilter?.isSaasFilterApplied) {
      if (!filteredObject["Filter by Saas"]) {
        filteredObject["Filter by Saas"] = [];
      }
      params += `${params && "&"}is_saas=${true}`;
      filteredObject["Filter by Saas"].push(`True`);
    }
    // Histor Visited Filter
    if (visitHistoryFilter.days_of_email_sent > 0) {
      params += `${params && "&"}days_of_website_history=${visitHistoryFilter.days_of_email_sent}`;
      if (!filteredObject["Visit History"]) {
        filteredObject["Visit History"] = [];
      }
      filteredObject["Visit History"].push(`${visitHistoryFilter.days_of_email_sent} days ago`);
    }

    // Communication Filter
    if (communicationFilter.option === "reached_out" && communicationFilter.reachedOut) {
      if (!filteredObject["Communication with Telescope"]) {
        filteredObject["Communication with Telescope"] = [];
      }
      params += `${params && "&"}has_email_sent=true`;
      filteredObject["Communication with Telescope"].push("Reached Out");
    } else if (communicationFilter.option === "not_reached_out" && !communicationFilter.reachedOut) {
      if (!filteredObject["Communication with Telescope"]) {
        filteredObject["Communication with Telescope"] = [];
      }
      params += `${params && "&"}has_email_sent=false`;
      filteredObject["Communication with Telescope"].push("Not Reached Out");
    }
    if (communicationFilter.days_of_email_sent > 0) {
      params += `${params && "&"}days_of_email_sent=${communicationFilter.days_of_email_sent}`;
      if (!filteredObject["Communication with Telescope"]) {
        filteredObject["Communication with Telescope"] = [];
      }
      filteredObject["Communication with Telescope"].push(`${communicationFilter.days_of_email_sent} days ago`);
    }
    // LLM Filter UUID
    if (llmFilterData && llmFilterData?.uuid) {
      params += `${params && "&"}` + `discovery_llm_search_uuid=${llmFilterData?.uuid}`;
    }
    return { params, filteredObject };
  };

  const formatFiltersPayload = (payload) => {
    const params = new URLSearchParams(payload);
    const formattedPayload = {};

    params.forEach((value, key) => {
      const formattedKey = key?.replace(/_/g, ' ')?.replace(/(\b\w)/g, char => char?.toUpperCase());

      if (formattedPayload?.hasOwnProperty(formattedKey)) {
        if (!Array?.isArray(formattedPayload[formattedKey])) {
          formattedPayload[formattedKey] = [formattedPayload[formattedKey]];
        }
        formattedPayload[formattedKey]?.push(value);
      } else {
        formattedPayload[formattedKey] = value;
      }
    });

    return formattedPayload;
  };

  const handleSubmit = async () => {
    const hasValue = checkLength();
    setFiltersApplied(hasValue);
    if (!hasValue) {
      dispatch({ type: actionTypes.RESET_DISCOVERY_FILTERS, data: {} })
      return;
    }
    if (!llmInputFilterClicked) {
      dispatch({ type: actionTypes.RESET_LLM_FILTERS })
    }
    llmInputFilterClicked = false;
    const { params: filtersPayload, filteredObject } = await getFiltersPayload();

    // Storing the Filters Payload for DiscoveryTable component
    dispatch(setDiscoveryFiltersPayload(filtersPayload));

    // // Construct the URL with the combined filters
    const apiUrlWithCombinedFilters = filtersPayload
      ? `?${filtersPayload}`
      : "";

    const formattedFilters = formatFiltersPayload(filtersPayload);
    amplitude.logEvent(`Discovery Performed`, { ...formattedFilters });
    try {
      setIsLoading(true);
      if (cancelToken) {
        cancelToken.cancel("operation cancelled due to new req")
      }
      const CancelToken = axios.CancelToken;
      cancelToken = CancelToken.source();
      // Make the API call with the constructed URL
      setAppliedFilterString(apiUrlWithCombinedFilters)
      const url = `${getAddress() + `/discovery${apiUrlWithCombinedFilters}`}`;
      const result = await getDiscoveryCompanies(url, cancelToken)(dispatch);

      if (result.status === "success") {
        const total_count = result?.data?.total_count;
        dispatch(setAppliedFilters({ filteredObject, total_count }));
        setIsLoading(false);
        setLLMFilters({
          ...llmFilter,
          enteredValue: "",
        })
        if (result?.data?.results?.length > 0) {
          setHasResults(true);
        } else {
          setHasResults(false);
        }
      } else {
        // setIsLoading(false);
        setError(result.errMsg);
        setHasResults(false);
        if (result.status === 'ERR_CANCELED') {
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const filterDisplayNames = (searchTerm) => {
    return result.filter((item) => {
      return item?.display_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });
  };

  const checkLength = () => {
    const hasValue =
      Object.entries(checkboxObject).some(([key, val]) => val.length) ||
      Object.values(sliderObject)
        .map(
          ({
            greaterThanThumbPosition,
            lesserThanThumbPosition,
            selectedTags,
          }) => {
            return selectedTags && selectedTags.some((i) => i.value) > 0;
          }
        )
        .some((value) => value) ||
      Object.entries(preTags).some(
        ([key, val]) =>
          val.length ||
          Object.entries(hqLocation).some(
            ([key, val]) => val["selected"]?.length
          )
      ) ||
      // countriesData?.length ||
      (yearFounded.greaterThanValue > 0 || yearFounded.lesserThanValue > 0) ||
      selectedFounderLocation.data?.length > 0 ||
      saasFilter?.isSaasFilterApplied ||
      (communicationFilter.reachedOut || communicationFilter.option !== "") ||
      (communicationFilter.days_of_email_sent > 0) ||
      (visitHistoryFilter.days_of_email_sent > 0) ||
      selectedGrowthsData.some(
        (tag) => tag.greaterThanValue || tag.lessThanValue
      ) || selectedGrowthsTag?.length;

    return hasValue;
  };

  const handleCheckboxChange = (value, isChecked, serverValue, arrayVal) => {
    setCheckboxObject((prevCheckboxObject) => {
      // Copy the previous state to avoid mutating it directly
      const updatedCheckboxObject = { ...prevCheckboxObject };

      if (value === "Select All") {
        updatedCheckboxObject[serverValue] = isChecked
          ? serverValue === "tp_owners"
            ? arrayVal.map((o) => o.email_address)
            : arrayVal.map((o) => o)
          : [];
      } else {
        // Check if serverValue exists in the checkboxObject
        if (updatedCheckboxObject.hasOwnProperty(serverValue)) {
          // Update the array based on the condition
          updatedCheckboxObject[serverValue] = isChecked
            ? serverValue === "tp_owners"
              ? [...updatedCheckboxObject[serverValue], value.email_address]
              : [...updatedCheckboxObject[serverValue], value]
            : serverValue === "tp_owners"
              ? updatedCheckboxObject[serverValue].filter(
                (checkedItem) => checkedItem !== value.email_address
              )
              : updatedCheckboxObject[serverValue].filter(
                (checkedItem) => checkedItem !== value
              );
        }
      }

      const isLastCheckboxUncheck =
        updatedCheckboxObject[serverValue]?.length === 0;

      if (isLastCheckboxUncheck) {
        setAccordionObject((prevAccordion) => {
          const updatedAccordion = { ...prevAccordion };
          const keyToUpdate = Object.keys(updatedAccordion).find(
            (key) => updatedAccordion[key].name === serverValue
          );

          if (keyToUpdate) {
            updatedAccordion[keyToUpdate].isAccordionOpen = false;
          }
          return updatedAccordion;
        });
      }

      return updatedCheckboxObject;
    });
  };

  const isTotalFunding = (val) => val.startsWith('total_funding_usd_no_data');

  // const isEmailTag = (val) => {
  //   let isEmailTag = false;
  //   isEmailTag = emailConstants.some(
  //     (i) => i.server_value === val?.toLowerCase()
  //   );
  //   return isEmailTag;
  // };

  // const isFundingTag = (val) => {
  //   let isFundingTag = false;
  //   isFundingTag = tagsInDays.some(
  //     (i) => i.server_value === val?.toLowerCase()
  //   );
  //   return isFundingTag;
  // };

  // const handleRemoveFilter = (val, display_name) => {
  //   if (val === "hasPresence") {
  //     setFilterCountry(display_name);
  //   } else if (val === "countries" || val === "states" || val === "cities") {
  //     setFilteredLocationObject({
  //       keys: val,
  //       value: display_name?.replace("HQ:", "")?.trim(),
  //     });
  //   } else if (val === "founder_countries" || val === "founder_states" || val === "founder_cities") {
  //     const type = selectedFounderLocation.type;
  //     const newData = selectedFounderLocation.data.filter(
  //       (location) => location !== display_name.replace(`Founder ${type.slice(0, -1).charAt(0).toUpperCase() + type.slice(1).toLowerCase()}: `, "").trim()
  //     );
  //     setSelectedFounderLocation({ type, data: newData });
  //     let removeFilter = display_name.split(":")
  //     setFounderLocationChange(removeFilter[1]?.trim());
  //   } else if (isTotalFunding(val)) {
  //     setSelectedTotalFundingData({
  //       trueFalse: 'false',
  //     });
  //   } else if (val === 'tp_status_since' && display_name.includes("TP Status since")) {
  //     setTpStatusSince('');
  //   } else if (isFundingTag(val)) {
  //     setPreTags((prevData) => {
  //       const updatedPreTags = { ...prevData };
  //       const updatedAb = Object.fromEntries(
  //         Object.entries(updatedPreTags).map(([key, value]) => [
  //           key,
  //           value.filter((item) => item.server_value !== val),
  //         ])
  //       );
  //       return updatedAb;
  //     });
  //   } else if (val === "is_saas") {
  //     setSaasFilter((prevState) => ({
  //       ...prevState,
  //       isSaasFilterApplied: false,
  //     }));
  //   } else if (val === "days_of_website_history") {
  //     setVisitHistoryFilter((prevState) => ({
  //       ...prevState,
  //       days_of_email_sent: "",
  //       visitHistoryValue: ""
  //     }));
  //   } else if (val === "reached_out" || val === "not_reached_out") {
  //     setCommunicationFilter((prevState) => ({
  //       ...prevState,
  //       option: "",
  //       reachedOut: false,
  //       notReachedOutValue: "",
  //       days_of_email_sent: 0,
  //     }));
  //   } else if (val === "days_of_email_sent") {
  //     setCommunicationFilter((prevState) => ({
  //       ...prevState,
  //       days_of_email_sent: "",
  //       notReachedOutValue: ""
  //     }));
  //   } else if (selectedGrowthsTag.some(item => item.value === val)) {
  //     setSelectedGrowthsTag(prevSelected => prevSelected.filter(item => item.value !== val));
  //   } else if (val?.includes(">=") || val?.includes("<=")) {
  //     const [, conditionKey, operator, conditionValueString] = val.match(
  //       /(.+?)\s*([<>]=?)\s*(\d+)/
  //     );
  //     let normalizedConditionKey = conditionKey
  //       .toLowerCase()
  //       .replace(/\s+/g, "_");
  //     normalizedConditionKey = normalizedConditionKey.replace(/_$/, "");

  //     if (normalizedConditionKey === "year_founded") {
  //       setYearFounded((prevYearFounded) => {
  //         const updatedYearFounded = { ...prevYearFounded };

  //         if (operator === ">=") {
  //           updatedYearFounded.greaterThanValue = "";
  //         } else if (operator === "<=") {
  //           updatedYearFounded.lesserThanValue = "";
  //         }

  //         return updatedYearFounded;
  //       });
  //     } else if (normalizedConditionKey?.startsWith("growth_")) {
  //       setSelectedGrowthsData((prevSelected) => {
  //         return prevSelected.map((item) => {
  //           if (item.value === normalizedConditionKey) {
  //             if (operator === ">=") {
  //               return { ...item, greaterThanValue: "" };
  //             } else if (operator === "<=") {
  //               return { ...item, lessThanValue: "" };
  //             }
  //           }
  //           return item;
  //         }).filter(item => item.greaterThanValue || item.lessThanValue);
  //       });
  //     }
  //     else {
  //       setSliderObject((prevSliderObject) => {
  //         const updatedObject = { ...prevSliderObject };

  //         if (updatedObject.hasOwnProperty(normalizedConditionKey)) {
  //           function mapOperator(operator) {
  //             if (operator === "<=") return "greaterThan";
  //             if (operator === ">=") return "lessThan";
  //             return operator;
  //           }
  //           // Extracting the operator and value from the string
  //           const regex = /<=|>=/;
  //           const [operator] = display_name.match(regex);
  //           let value;
  //           let newObject;
  //           if (
  //             updatedObject[
  //               normalizedConditionKey
  //             ].selectedTags[0]?.hasOwnProperty("displayName")
  //           ) {
  //             value =
  //               value === "below_20" || value === "above_1000"
  //                 ? null
  //                 : operator === "<="
  //                   ? updatedObject[normalizedConditionKey].selectedTags[0]
  //                     ?.minCount
  //                   : updatedObject[normalizedConditionKey].selectedTags[0]
  //                     ?.maxCount;
  //             newObject = {
  //               operator: mapOperator(operator),
  //               value: value,
  //             };
  //             updatedObject[normalizedConditionKey].selectedTags = value
  //               ? [newObject]
  //               : [];
  //           } else {
  //             let filteredTag = updatedObject[
  //               normalizedConditionKey
  //             ].selectedTags?.filter((i) => i.operator === mapOperator(operator));
  //             newObject = {
  //               ...filteredTag[0],
  //             };
  //             updatedObject[normalizedConditionKey].selectedTags = filteredTag[0]
  //               ?.value
  //               ? [newObject]
  //               : [];
  //           }

  //           if (selectedTotalFundingData.trueFalse && !selectedTotalFundingData.trueFalse.length) {
  //             setSelectedTotalFundingData([]);
  //           }
  //         }
  //         return updatedObject;
  //       });
  //     }
  //   } else {
  //     setCheckboxObject((prevCheckboxObject) => {
  //       const updatedCheckboxObject = { ...prevCheckboxObject };

  //       const updatedAb = Object.fromEntries(
  //         Object.entries(updatedCheckboxObject).map(([key, value]) => [
  //           key,
  //           value.filter((item) => item !== val),
  //         ])
  //       );
  //       if (updatedAb.tp_status?.length === 0 && tpStatusOperator === 'was') {
  //         setTpStatusSince('');
  //       }
  //       return updatedAb;
  //     });
  //   }

  //   const isLastFilterForField =
  //     checkboxObject[val]?.length === 1 ||
  //     !sliderObject[val]?.selectedTags?.length ||
  //     (val === "growth" && selectedGrowthsData?.length === 1) ||
  //     (val === 'growth_tags' && selectedGrowthsTag?.length === 1) ||
  //     (val === "total_funding_on" && selectedTotalFundingData?.length === 1) ||
  //     (Object.entries(yearFounded).some(([key, val]) => val.length === 1));
  //   if (isLastFilterForField) {
  //     setAccordionObject((prevAccordion) => {
  //       const updatedAccordion = { ...prevAccordion };
  //       let keyToUpdate = Object.keys(updatedAccordion).find(
  //         (key) => updatedAccordion[key].name === val
  //       );

  //       if (
  //         val === "countries" ||
  //         val === "states" ||
  //         val === "cities" 
  //         // ||val === "hasPresence"
  //       ) {
  //         const hqLocationKeys = ["countries", "states", "cities"];
  //         const selectedCount = hqLocationKeys.reduce(
  //           (count, key) => count + (hqLocation[key]?.selected.length || 0),
  //           0
  //         );
  //         const countriesDataCount = countriesData?.length;
  //         const totalCount = selectedCount + countriesDataCount;

  //         if (totalCount === 1) {
  //           keyToUpdate = "headquarter_search";
  //         }
  //       }

  //       if (keyToUpdate) {
  //         updatedAccordion[keyToUpdate].isAccordionOpen = false;
  //       }
  //       return updatedAccordion;
  //     });
  //     dispatch({
  //       type: actionTypes.RESET_LLM_FILTERS
  //     })
  //     setLLMFilters({
  //       enteredValue: "",
  //       searchTerm: "",
  //     })
  //     setCurrentSelection("");
  //   }
  // };

  const handleRemoveFilterGroup = (filterValue) => {
    if (filterValue === "headquarter_search") {
      setHqLocation({});
      setFilteredLocationObject({ keys: "all", value: "all" });
    } else if (filterValue === "year_founded") {
      setYearFounded({ greaterThanValue: "", lesserThanValue: "" });
    } else if (filterValue === "communication_with_telescope") {
      setCommunicationFilter({ option: "", days_of_email_sent: 0 });
    } else if (filterValue === "visit_history") {
      setVisitHistoryFilter({
        days_of_email_sent: 0,
        visitHistoryValue: "",
        timeUnit: "days"
      })
    } else if (filterValue === "investment_data") {
      setSliderObject((prevSliderObject) => {
        const updated = { ...prevSliderObject };
        updated["investment_data"].selectedTags = [];
        return updated;
      });
    } else if (filterValue === "employee_insights") {
      setSliderObject((prevSliderObject) => {
        const updated = { ...prevSliderObject };
        updated["employee_count"].selectedTags = [];
        return updated;
      });
    } else if (filterValue === "growth") {
      setSelectedGrowthsData([]);
    } else if (filterValue === "growth_tags") {
      setSelectedGrowthsTag([]);
    } else if (filterValue === "founder_location_search") {
      setSelectedFounderLocation({ type: "", data: [] });
      setFounderLocationChange("all");
    } else {
      setCheckboxObject((prevCheckboxObject) => {
        const updated = { ...prevCheckboxObject };
        updated[filterValue] = [];
        return updated;
      });
    }
  }

  const openHistorySlider = () => {
    setHistorySlider(!historySlider);
    dispatch(getDiscoverySearchHistory(axiosDiscoverySearchHistory))
      .then((res) => {
        if (res.status === "success") {
          setSearchHistory(res?.data);
        }
      });
  }

  const handleCloseHistorySlider = () => {
    setHistorySlider(false);
  };

  const renderAppliedFilters = () => {
    const maxDisplayedFilters = 5;
    let resultArray = [];
    
    Object.entries(sliderObject).forEach(([key, { selectedTags }]) => {
      const format = key
        ?.replace(/_/g, " ")
        .replace(/\b\w/g, (l) => l.toUpperCase());
      // Check if selectedTags exist and add them to the resultArray
      if (selectedTags && selectedTags?.length) {
        selectedTags?.forEach((tag) => {
          if (tag?.operator === "greaterThan" && tag?.value !== "") {
            resultArray.push(`${format} >= ${tag?.value}`);
          } else if (tag?.operator === "lessThan" && tag?.value !== "") {
            resultArray.push(`${format} <= ${tag?.value}`);
          } else if (
            tag?.minCount !== undefined &&
            tag?.maxCount !== undefined
          ) {
            resultArray.push(`${format} >= ${tag?.minCount}`);
            resultArray.push(`${format} <= ${tag?.maxCount}`);
          } else if (tag?.value === "below_20") {
            resultArray.push(`${format} <= ${tag?.count}`);
          } else if (tag?.value === "above_1000") {
            resultArray.push(`${format} >= ${tag?.count}`);
          }
        });
      }
    });

    Object.entries(yearFounded).forEach(([key, value]) => {
      // Check if the value exists and add it to the resultArray with appropriate text
      if (value) {
        resultArray.push(
          key === "greaterThanValue"
            ? `Year Founded >= ${value}`
            : `Year Founded <= ${value}`
        );
      }
    });

    let appliedSliderFilters = resultArray?.length
      ? resultArray?.map((i) => {
        return {
          display_name: i,
          value: i,
        };
      })
      : [];

    let appliedFilters = []
      .concat(
        ...Object.entries(checkboxObject).map(([key, val]) => {
          if (val?.length) {
            return val.map((value) => {
              let displayName = value;

              if (key === "tp_owners") {
                const owner = tpOwners.find(
                  (owner) => owner.email_address === value
                );
                displayName = owner ? owner.full_name : value;
              }

              return {
                display_name: displayName,
                value: value,
              };
            });
          }
        })
      )
      .filter(Boolean);

    let appliedTags = []
      .concat(
        ...Object.entries(preTags).map(([key, val]) => {
          if (val?.length) {
            return val.map((item) => {
              return {
                display_name: `Last Funding On: ${item?.displayName}`,
                value: item?.server_value,
              };
            });
          }
        })
      )
      .filter(Boolean);

    let growthFilter = selectedGrowthsData?.flatMap((i) => {
      const filters = [];
      if (i.greaterThanValue) {
        filters.push({
          display_name: `${i.displayName} >= ${i.greaterThanValue}`,
          value: `${i.displayName} >= ${i.greaterThanValue}`,
        });
      }
      if (i.lessThanValue) {
        filters.push({
          display_name: `${i.displayName} <= ${i.lessThanValue}`,
          value: `${i.displayName} <= ${i.lessThanValue}`,
        });
      }
      return filters;
    });

    let growthTags = selectedGrowthsTag?.map(i => {
      return {
        display_name: `${i?.displayName}`,
        value: `${i?.value}`
      };
    })

    let saasTags = [];
    if (saasFilter?.isSaasFilterApplied) {
      saasTags.push({
        display_name: "Saas Applied",
        value: "is_saas",
      });
    }

    // let totalFunding = [];
    // if (selectedTotalFundingData?.trueFalse) {
    //   totalFunding.push({
    //     display_name: `Funding: ${selectedTotalFundingData.trueFalse === "true" ? "True" : "False"}`,
    //     value: `total_funding_usd_no_data=${selectedTotalFundingData.trueFalse}`,
    //   });
    // }

    let hqLocations = []
      .concat(
        ...Object.entries(hqLocation).map(([key, val]) => {
          if (val?.selected?.length) {
            return val?.selected.map((item) => {
              return {
                display_name: `HQ: ${item}`,
                value: `${key}`,
              };
            });
          }
        })
      )
      .filter(Boolean);

    // let countriesD = countriesData?.map((i) => {
    //   return {
    //     display_name: `${i}`,
    //     value: `hasPresence`,
    //   };
    // });

    let tpStatusSinceFilter = tpStatusSince !== null && tpStatusSince !== ''
      ? {
        display_name: `TP Status since ${tpStatusSince} days`,
        value: 'tp_status_since',
      }
      : null;

    let founderLocation = [];
    if (selectedFounderLocation?.type && selectedFounderLocation?.data) {
      founderLocation = selectedFounderLocation.data.map((location) => ({
        display_name: `Founder ${selectedFounderLocation.type.slice(0, -1).charAt(0).toUpperCase() + selectedFounderLocation.type.slice(1).toLowerCase()}: ${location}`,
        value: `founder_${selectedFounderLocation.type}`,
      }))
    };

    let visitHistoryFilterApplied = [];
    if (visitHistoryFilter.days_of_email_sent) {
      visitHistoryFilterApplied.push({
        display_name: `History Visited: ${visitHistoryFilter.visitHistoryValue} ${visitHistoryFilter.timeUnit} ago`,
        value: "days_of_website_history",
      });
    }

    let communicationFilterFilters = [];
    if (communicationFilter.option === "reached_out") {
      communicationFilterFilters.push({
        display_name: "Have we sent an email?",
        value: "reached_out",
      });
    } else if (communicationFilter.option === "not_reached_out") {
      communicationFilterFilters.push({
        display_name: "Have we not sent an email?",
        value: "not_reached_out",
      });
    }
    if (communicationFilter.days_of_email_sent) {
      communicationFilterFilters.push({
        display_name: `Email Sent: ${communicationFilter.notReachedOutValue} ${communicationFilter.timeUnit} ago`,
        value: "days_of_email_sent",
      });
    }

    appliedFilters = [].concat(
      ...appliedFilters,
      appliedSliderFilters,
      appliedTags,
      growthFilter,
      growthTags,
      hqLocations,
      // countriesD,
      tpStatusSinceFilter ? [tpStatusSinceFilter] : [],
      founderLocation,
      communicationFilterFilters,
      visitHistoryFilterApplied,
      saasTags
    );

    const visibleFilters = showAllFilters
      ? appliedFilters
      : appliedFilters.slice(0, maxDisplayedFilters);

    return (
      <div className="internal-container d-flex mb-2 align-items-center">
        {appliedFilters.length && (
          <span className={isLoading ? "clear-all-button disabled" : "clear-all-button"} onClick={clearSearch}>
            <div className="font-weight-bold clear-top-bar">
              X Clear filters
            </div>
          </span>
        )}
        {(llmFilterData && Object.keys(llmFilterData)?.length > 0 || filtersApplied) && (
          <span className={isLoading ? "view-search-history disabled" : "view-search-history" } onClick={openHistorySlider}>
            <div className="font-weight-bold view-search-button">
              <EyeIcon style={{ marginRight: '6px' }} /> View search history
            </div>
          </span>
        )}
        {/* {visibleFilters?.map((filter, index) => (
          <div key={index} className="custom-badge">
            <div className="badge">{filter?.display_name}</div>
            <span
              className="filter-remove"
              onClick={() =>
                handleRemoveFilter(filter.value, filter.display_name)
              }
            >
              X
            </span>
          </div>
        ))} */}
        {/* {appliedFilters.length > maxDisplayedFilters && !showAllFilters && (
          <span
            className="custom-badge"
            onClick={() => setShowAllFilters(true)}
          >
            <div className="badge show-more">
              +<b>{appliedFilters.length - maxDisplayedFilters}</b>
            </div>
          </span>
        )}
        {showAllFilters && appliedFilters.length > 5 && (
          <span
            className="custom-badge"
            onClick={() => setShowAllFilters(false)}
          >
            <div className="font-weight-bold show-less badge">Show Less</div>
          </span>
        )} */}
      </div>
    );
  };

  const handleResetAll = () => {
    setFiltersApplied(false);
    // Reset search details filters
    dispatch({ type: actionTypes.RESET_APPLIED_FILTERS })
    // Clear checkboxes
    if (Object.entries(checkboxObject).some(([key, val]) => val.length)) {
      setCheckboxObject((prevCheckboxObject) => {
        const updatedCheckboxObject = { ...prevCheckboxObject };

        for (const key in updatedCheckboxObject) {
          if (updatedCheckboxObject.hasOwnProperty(key)) {
            updatedCheckboxObject[key] = [];
          }
        }
        return updatedCheckboxObject;
      });
      setTpStatusSince('');
    }
    if (
      Object.values(sliderObject).some(
        ({ selectedTags }) => selectedTags?.length > 0
      )
    ) {
      setSliderObject((prevSliderObject) => {
        const updatedSliderObject = { ...prevSliderObject };

        for (const key in updatedSliderObject) {
          if (updatedSliderObject.hasOwnProperty(key)) {
            updatedSliderObject[key] = {
              greaterThanValue: "",
              lessThanValue: "",
              selectedTags: [],
              symbol: updatedSliderObject[key].symbol,
              maxValue: updatedSliderObject[key].maxValue,
            };
          }
        }
        return updatedSliderObject;
      });
    }

    if (Object.entries(preTags).some(([key, val]) => val.length)) {
      setPreTags((prevCheckboxObject) => {
        const updatedCheckboxObject = { ...prevCheckboxObject };

        for (const key in updatedCheckboxObject) {
          if (updatedCheckboxObject.hasOwnProperty(key)) {
            updatedCheckboxObject[key] = [];
          }
        }
        return updatedCheckboxObject;
      });
    }

    if (selectedGrowthsData?.length) {
      setSelectedGrowthsData([]);
    }
    if (selectedGrowthsTag?.length) {
      setSelectedGrowthsTag([])
    }
    if (Object.entries(selectedTotalFundingData).some(([key, val]) => val?.length)) {
      setSelectedTotalFundingData({
        trueFalse: 'false',
      });
    }
    // if (countriesData?.length) {
    //   setFilterCountry("all");
    // }
    if (yearFounded?.greaterThanValue > 0 || yearFounded?.lesserThanValue > 0) {
      setYearFounded({
        greaterThanValue: "",
        lesserThanValue: "",
      });
    }
    if (
      Object.entries(hqLocation).some(([key, val]) => val["selected"]?.length)
    ) {
      setFilteredLocationObject({
        keys: "all",
        value: "all",
      });
    }
    if (selectedFounderLocation?.data?.length > 0) {
      setSelectedFounderLocation({
        data: [],
        type: ""
      })
      setFounderLocationChange("all")
    }
    if (communicationFilter?.option === "reached_out" || communicationFilter?.option === "not_reached_out" || communicationFilter?.days_of_email_sent > 0) {
      setCommunicationFilter({
        option: "",
        reachedOut: false,
        notReachedOutValue: "",
        timeUnit: "days",
        days_of_email_sent: 0,
      })
    }
    if (visitHistoryFilter?.days_of_email_sent > 0) {
      setVisitHistoryFilter({
        days_of_email_sent: 0,
        visitHistoryValue: "",
        timeUnit: "days"
      })
    }
    if (saasFilter?.isSaasFilterApplied) {
      setSaasFilter({
        isSaasFilterApplied: false,
      });
    }
    setOpenItems([]);
  };

  const clearSearch = () => {
    setIsLoading(false);
    setFilterVisible(false);

    setLLMFilters({
      searchTerm: "",
    })
    dispatch({
      type: actionTypes.RESET_LLM_FILTERS
    })
    handleResetAll();
  }

  const handleTpStatusOperatorChange = (operator) => {
    setTpStatusOperator(operator);
  };

  const renderTopButtons = (item) => {
    switch (item) {
      case "tp_status":
        return (
          <div className="flex-wrap mb-3">
            <span
              className={
                tpStatusOperator === "is"
                  ? "selected tp-button btn btn-sm"
                  : "tp-button btn btn-sm"
              }
              onClick={() => handleTpStatusOperatorChange("is")}
            >
              Currently is
            </span>
            <span
              className={
                tpStatusOperator === "was"
                  ? "selected tp-button btn btn-sm"
                  : "tp-button btn btn-sm"
              }
              onClick={() => handleTpStatusOperatorChange("was")}
            >
              Status was
            </span>
          </div>
        );
      default:
        return null;
    }
  };

  const render_founderLocationTags = () => {
    return founderLocationTags?.map((item, id) => {
      return <span
        key={id}
        className={
          item.serverValue === founderLocationTag?.serverValue
            ? "selected founderLocation-button btn btn-sm mt-3"
            : "founderLocation-button btn btn-sm mt-3"
        }
        onClick={() => handleFounderLocationChange(item)}
      >
        {item?.displayName}
      </span>
    })
  }

  const handleFounderLocationChange = (val) => {
    setFounderLocationTag(val)
  }

  const handleFounderChange = (founderObj) => {
    setSelectedFounderLocation({
      ...selectedFounderLocation,
      type: founderLocationTag?.serverValue,
      data: founderObj
    })
    setLLmFounderLocation({});
    setFounderLocationChange("");
  }

  const handleTpStatusSinceChange = (value) => {
    if (/^\d*$/.test(value)) {
      setTpStatusSince(value);
    }
  };

  const handleSliderTagChange = (selectedTag, fieldName) => {
    setSliderObject((prevSliderObject) => {
      const updatedSliderObject = { ...prevSliderObject };
      // Initialize selectedTags if not already present
      if (!updatedSliderObject[fieldName]) {
        updatedSliderObject[fieldName] = {
          selectedTags: [],
          greaterThanValue: "",
          lessThanValue: "",
        };
      }
      if (!updatedSliderObject[fieldName].selectedTags) {
        updatedSliderObject[fieldName].selectedTags = [];
      }

      let selectedTags = updatedSliderObject[fieldName].selectedTags;
      if (selectedTag.value) {
        const isTagSelected = selectedTags?.some(
          (tag) => tag.value === selectedTag?.value
        );
        if (isTagSelected) {
          selectedTags = selectedTags?.filter(
            (tag) => tag.value !== selectedTag?.value
          );
        } else {
          selectedTags = [selectedTag];
        }

        // Clear numeric inputs if a tag is selected
        updatedSliderObject[fieldName].greaterThanValue = "";
        updatedSliderObject[fieldName].lessThanValue = "";
      } else {
        const hasGreaterThan = selectedTag.greaterThan !== undefined;
        const hasLessThan = selectedTag.lessThan !== undefined;
        // Filter out existing greaterThan and lessThan values
        if (hasGreaterThan) {
          selectedTags = selectedTags?.filter(
            (tag) => tag.operator !== "greaterThan"
          );
        }
        if (hasLessThan) {
          selectedTags = selectedTags?.filter(
            (tag) => tag.operator !== "lessThan"
          );
        }
        if (hasGreaterThan) {
          selectedTags.push({
            value: selectedTag.greaterThan,
            operator: "greaterThan",
          });
        }
        if (hasLessThan) {
          selectedTags.push({
            value: selectedTag.lessThan,
            operator: "lessThan",
          });
        }
        if (hasGreaterThan || hasLessThan) {
          selectedTags = selectedTags?.filter((tag) => !tag.displayName);
        }
      }
      updatedSliderObject[fieldName].selectedTags = selectedTags;

      return updatedSliderObject;
    });
  };

  // const handleTagChange = (tagItem, item) => {
  //   setPreTags((prevTags) => {
  //     if (prevTags[item]?.[0]?.server_value === tagItem.server_value) {
  //       return { ...prevTags, [item]: [] };
  //     } else {
  //       return { ...prevTags, [item]: [tagItem] };
  //     }
  //   });
  // };

  const handleTotalFundingClick = (value) => {
    setSelectedTotalFundingData((prevData) => ({
      ...prevData,
      trueFalse: prevData.trueFalse === value ? '' : value
    }));
  };


  const handleShowAll = (item, array) => {
    setCheckboxObjectVisiblity((prevCheckboxObject) => {
      // Copy the previous state to avoid mutating it directly
      const updatedObject = { ...prevCheckboxObject };
      // Check if serverValue exists in the checkboxObject
      if (updatedObject.hasOwnProperty(item)) {
        updatedObject[item]["count"] = array?.length;
      }
      return updatedObject;
    });
  };

  const handlehide = (item) => {
    setCheckboxObjectVisiblity((prevCheckboxObject) => {
      // Copy the previous state to avoid mutating it directly
      const updatedObject = { ...prevCheckboxObject };
      // Check if serverValue exists in the checkboxObject
      if (updatedObject.hasOwnProperty(item)) {
        updatedObject[item]["count"] = 20;
      }
      return updatedObject;
    });
  };

  const getArrayVal = (item) => {
    switch (item.server_value) {
      case "tp_owners":
        return tpOwners ? [...tpOwners] : [];
      case "tp_status":
        return tpStatusOptions ? [...tpStatusOptions] : [];
      case "countries":
        return countries ? [...countries] : [];
      case "states":
        return states ? [...states] : [];
      case "cities":
        return cities ? [...cities] : [];
      case "status":
        return status ? [...status] : [];
      default:
        return [];
    }
  };

  useEffect(() => {
    let indexes = [];
    Object.entries(accordionObject).forEach(([key, val]) => {
      const isEmployeeInsights = key === "employee_insights";
      if (
        (val?.name === currentSelection ||
          checkboxObject[val.name]?.length ||
          sliderObject[val.name]?.selectedTags?.length > 0 ||
          (selectedGrowthsData?.some((item) => item.greaterThanValue !== null || item.lessThanValue !== null) && val?.name === "growth") ||
          // (countriesData?.length && val?.name === "headquarter_search") ||
          (val?.name === "headquarter_search" &&
            (hqLocation?.countries?.selected.length > 0 ||
              hqLocation?.states?.selected.length > 0 ||
              hqLocation?.cities?.selected.length > 0)) ||
          (isEmployeeInsights &&
            (employeeInsightsTags.some(
              (tag) => sliderObject[tag]?.selectedTags?.length > 0
            ) ||
              employeeInsightsTags.some(
                (tag) => checkboxObject[tag]?.length > 0
              ))) ||
          (val?.name === "communication_with_telescope" &&
            (communicationFilter?.option !== '' || communicationFilter?.days_of_email_sent || communicationFilter?.notReachedOutValue) &&
            val.isAccordionOpen) ||
          (val?.name === "is_saas" &&
            (saasFilter?.isSaasFilterApplied)) ||
          (val?.name === "visit_history" &&
            (visitHistoryFilter?.days_of_email_sent || visitHistoryFilter?.visitHistoryValue) &&
            val.isAccordionOpen) ||
          (val?.name === "founder_location_search" &&
            selectedFounderLocation.data?.length > 0
          ) || (selectedGrowthsTag?.length && val?.name === "growth_tags") ||
          (val?.name === "year_founded" &&
            (yearFounded.greaterThanValue || yearFounded.lesserThanValue))) &&
        val.isAccordionOpen
      ) {
        indexes.push(`${val?.index}`);
      } else if (val?.name === currentSelection && !val?.isAccordionOpen) {
        indexes = indexes.filter((item, ind) => ind !== val?.index);
      }
    });
    setOpenItems(indexes);
  }, [accordionObject, llmFilterData]);

  // const handleAccordionChange = (newOpenItems) => {
  //   setAccordionObject((prevAccordion) => {
  //     // Copy the previous state to avoid mutating it directly
  //     const updatedAccordion = { ...prevAccordion };
  //     // Find the key based on the provided index
  //     const keyToUpdate = Object.keys(updatedAccordion).find(
  //       (key) => updatedAccordion[key].index === Number(newOpenItems)
  //     );
  //     // Update hasValue to true for the specified key
  //     if (keyToUpdate) {
  //       updatedAccordion[keyToUpdate].isAccordionOpen =
  //         currentSelection === updatedAccordion[keyToUpdate]?.name
  //           ? !updatedAccordion[keyToUpdate].isAccordionOpen
  //           : checkboxObject[keyToUpdate]?.length ||
  //             sliderObject[keyToUpdate]?.selectedTags?.length > 0 ||
  //             (keyToUpdate === "employee_insights" &&
  //               employeeInsightsTags.some(
  //                 (tag) => sliderObject[tag]?.selectedTags?.length > 0
  //               )) ||
  //             (selectedGrowthsData?.some((item) => item.greaterThanValue !== null || item.lessThanValue !== null) &&
  //               keyToUpdate === "growth") ||
  //             (countriesData?.length && keyToUpdate === "headquarter_search") ||
  //             (keyToUpdate === "headquarter_search" &&
  //               (hqLocation?.countries?.selected.length > 0 ||
  //                 hqLocation?.states?.selected.length > 0 ||
  //                 hqLocation?.cities?.selected.length > 0)) ||
  //             (keyToUpdate === "founder_location_search" &&
  //               selectedFounderLocation.data?.length > 0
  //             ) || (selectedGrowthsTag?.length && keyToUpdate === "growth_tags") ||
  //             (keyToUpdate === "is_saas" &&
  //               (saasFilter?.isSaasFilterApplied)) ||
  //             (keyToUpdate === "visit_history" &&
  //               (visitHistoryFilter?.days_of_email_sent || visitHistoryFilter?.visitHistoryValue)) ||
  //             (keyToUpdate === "communication_with_telescope" &&
  //               (communicationFilter?.option !== '' || communicationFilter?.reachedOut.no || communicationFilter?.days_of_email_sent || communicationFilter?.notReachedOutValue)) ||
  //             (keyToUpdate === "year_founded" &&
  //               (yearFounded.greaterThanValue || yearFounded.lesserThanValue))
  //             ? !updatedAccordion[keyToUpdate].isAccordionOpen
  //             : true;
  //     }
  //     return updatedAccordion;
  //   });
  //   setCurrentSelection(column_order[newOpenItems]);
  // };

  const handleGrowthTagChange = (tag, newValues) => {
    setSelectedGrowthsData((prevSelected) => {
      const tagIndex = prevSelected.findIndex((item) => item.value === tag.value);
      if (tagIndex !== -1) {
        // If the tag is already selected, update the values
        const updatedTag = {
          ...prevSelected[tagIndex],
          greaterThanValue: newValues.greaterThan,
          lessThanValue: newValues.lessThan,
        };
        return [
          ...prevSelected.slice(0, tagIndex),
          updatedTag,
          ...prevSelected.slice(tagIndex + 1),
        ];
      } else {
        // Add the tag if it's not already selected
        return [
          ...prevSelected,
          { value: tag.value, displayName: tag.displayName, greaterThanValue: newValues.greaterThan, lessThanValue: newValues.lessThan },
        ];
      }
    });
  };

  const renderGrowthTags = () => {
    return (
      <>
        {growthTags.map((tag, index) => (
          <div key={index} className="growth-tag-container mt-3">
            <Row>
              <span className="growth-display-name">{tag.displayName}</span>
              <InputFields
                onChange={(newValues) => handleGrowthTagChange(tag, newValues)}
                selectedTag={[
                  { operator: "greaterThan", value: selectedGrowthsData.find(item => item.value === tag.value)?.greaterThanValue || "" },
                  { operator: "lessThan", value: selectedGrowthsData.find(item => item.value === tag.value)?.lessThanValue || "" },
                ]}
                fieldName={tag.value}
              />
            </Row>
          </div>
        ))}
      </>
    );
  };

  const handleGrowthsClick = (i) => {
    setSelectedGrowthsTag((prevSelected) => {
      if (prevSelected.some(item => item.value === i.value)) {
        return prevSelected.filter((item) => item?.value !== i.value);
      } else {
        return [...prevSelected, i];
      }
    });
  }

  const renderGrowthTagsFilter = () => {
    return (
      <div className='d-flex flex-wrap mb-1 mt-1'>
        {growthsData?.length && (
          growthsData?.map((i, ind) => {
            return (
              <div key={ind} className="growth-tag-container">
                <div className="growth-tags-container" onClick={() => handleGrowthsClick(i)}>
                  <div className='tag-count d-flex'>
                    <div className={selectedGrowthsTag?.some(selectedItem => selectedItem.value === i.value) ?
                      "growth-tags active pt-1" : "growth-tags pt-1"}>
                      {i?.displayName}
                      <span className='growth-tool-tip'><PlainTootip content={i.description} name={i?.displayName} height={"12px"} width={"12px"} isActive={selectedGrowthsTag?.some(selectedItem => selectedItem.value === i.value)} /></span>
                    </div>
                    <span className='growth-tag-count pt-1'>{i?.count}</span>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  }

  const handleLocation = (form) => {
    setHqLocation(form);
    setFilteredLocationObject({
      keys: "",
      value: "",
    });
    setPopulateLocation([])
  };
  // const handleCountriesChange = (val) => {
  //   console.log("handleCountriesChange", val);
  //   // setCountriesData(val?.selected);
  //   // setFilterCountry("");
  // };

  const render_hq_countries = () => {
    return (
      <LocationFilter
        onChange={handleLocation}
        filteredLocation={filteredLocationObject}
        // onCountriesChange={handleCountriesChange}
        // filteredCountry={filterCountry}
        populateLocationData={populateLocation}
        hqLocationSaved={hqLocation}
      />
    );
  };

  const renderTotalFundingTags = () => {
    const investmentData = sliderObject['investment_data'] || {};
    const hasValues = investmentData.selectedTags?.some(tag => tag.value !== '');

    return (
      <div className="d-flex flex-wrap mt-3">
        <Row>
          <span className="total-funding-header">Total Funding</span>
          <InputFields
            onChange={(e) => handleSliderTagChange(e, 'investment_data')}
            selectedTag={sliderObject['investment_data']?.selectedTags || []}
            fieldName='investment_data'
          />
        </Row>
        <div className="total-funding-options">
          <div className="d-flex mb-2">
            <span className="bold-font">Include Funding Data Not Available
              <span className="px-1">
                <PlainTootip
                  content={totalFundingInfoIcon}
                  name="Investment"
                  height={"12px"}
                  width={"12px"}
                />
              </span>
            </span>
          </div>
          <div className="mt-2">
            <Form.Check
              type="checkbox"
              label="Yes"
              id="True"
              name="true"
              checked={selectedTotalFundingData.trueFalse === 'true'}
              onChange={() => handleTotalFundingClick('true')}
              disabled={!hasValues}
            />
            <Form.Check
              type="checkbox"
              label="No"
              id="False"
              name="false"
              // checked={selectedTotalFundingData.trueFalse === 'false'}
              checked={selectedTotalFundingData.trueFalse === 'false'}
              onChange={() => handleTotalFundingClick('false')}
              disabled={!hasValues}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleCommunicationOptionChange = (option) => {
    // Deselect the current option if it's already selected
    const newOption = communicationFilter.option === option ? "" : option;

    setCommunicationFilter({
      ...communicationFilter,
      option: newOption,
      reachedOut: newOption === "reached_out", // true if reached_out, false otherwise
      notReachedOutValue: newOption === "" ? "" : communicationFilter.notReachedOutValue, // Clear days if deselecting option
      days_of_email_sent: newOption === "" ? 0 : communicationFilter.days_of_email_sent, // Clear days if deselecting option
    });
  };

  // const handleIsSaasToggle = (isOn) => {
  //   setSaasFilter((prevState) => ({
  //     ...prevState,
  //     isSaasFilterApplied: isOn,
  //   }));
  // };

  // const renderIsSaasFilter = () => {
  //   return (
  //     <div className="growth-tag-container">
  //       <Form.Check
  //         type="switch"
  //         id="custom-switch"
  //         label="Filter by Saas"
  //         checked={saasFilter.isSaasFilterApplied}
  //         onChange={(e) => handleIsSaasToggle(e.target.checked)}
  //       />
  //     </div>
  //   );
  // };

  const handleNotReachedOutChange = (e) => {
    let value = parseInt(e.target.value, 10);
    const maxMonthsValue = 12;

    if (communicationFilter.timeUnit === "months") {
      value = Math.min(value, maxMonthsValue);
    }
    // Calculate days based on timeUnit
    const days = communicationFilter.timeUnit === "months"
      ? value * 30
      : communicationFilter.timeUnit === "years"
        ? value * 365
        : value;

    setCommunicationFilter((prevState) => ({
      ...prevState,
      notReachedOutValue: value,
      days_of_email_sent: days !== 0 ? days : 0,
    }));
  };

  const handleTimeUnitChange = (e) => {
    const newTimeUnit = e.target.value;
    let currentValue = communicationFilter.notReachedOutValue;

    if (newTimeUnit === "months") {
      currentValue = Math.min(currentValue, 12);
    }

    const newDays = newTimeUnit === "months"
      ? currentValue * 30
      : newTimeUnit === "years"
        ? currentValue * 365
        : currentValue;

    setCommunicationFilter((prevState) => ({
      ...prevState,
      timeUnit: newTimeUnit,
      notReachedOutValue: currentValue,
      days_of_email_sent: newDays !== 0 ? newDays : 0,
    }));
  };

  const handleVisitHistoryChange = (e) => {
    let value = parseInt(e.target.value, 10);
    const maxMonthsValue = 12;

    if (visitHistoryFilter.timeUnit === "months") {
      value = Math.min(value, maxMonthsValue);
    }
    // Calculate days based on timeUnit
    const days = visitHistoryFilter.timeUnit === "months"
      ? value * 30
      : visitHistoryFilter.timeUnit === "years"
        ? value * 365
        : value;

    setVisitHistoryFilter((prevState) => ({
      ...prevState,
      visitHistoryValue: value,
      days_of_email_sent: days !== 0 ? days : 0,
    }));
  };

  const handleHistoryTimeUnitChange = (e) => {
    const newTimeUnit = e.target.value;
    let currentValue = visitHistoryFilter.visitHistoryValue;

    if (newTimeUnit === "months") {
      currentValue = Math.min(currentValue, 12);
    }

    const newDays = newTimeUnit === "months"
      ? currentValue * 30
      : newTimeUnit === "years"
        ? currentValue * 365
        : currentValue;

    setVisitHistoryFilter((prevState) => ({
      ...prevState,
      timeUnit: newTimeUnit,
      visitHistoryValue: currentValue,
      days_of_email_sent: newDays !== 0 ? newDays : 0,
    }));
  };

  const renderVisitHistoryFilter = () => {
    const { visitHistoryValue, timeUnit } = visitHistoryFilter;

    return (
      <div className="communication-container mt-3">
        <div className="not-reached-out-options">
          <Row className="mb-2">
            <span className="mb-2">Website visited X days ago</span>
            <Col xs={7}>
              <Form.Control
                type="number"
                value={visitHistoryValue}
                onChange={handleVisitHistoryChange}
                min="0"
                max={timeUnit === "months" ? "12" : ""}
                placeholder="Enter number"
              />
            </Col>
            <Col xs={5} className="communication-dropdown">
              <Form.Select
                as="select"
                value={timeUnit}
                onChange={handleHistoryTimeUnitChange}
              >
                <option value="days">Days</option>
                <option value="months">Months</option>
                <option value="years">Years</option>
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const renderCommunicationFilter = () => {
    const { option, notReachedOutValue, timeUnit } = communicationFilter;

    return (
      <div className="communication-container mt-3">
        <div className="flex-wrap mb-2">
          <span
            className={
              option === "reached_out"
                ? "selected tp-button btn mb-2"
                : "tp-button btn mb-2"
            }
            onClick={() => handleCommunicationOptionChange("reached_out")}
          >
            <div className="d-flex communication-btn flex-align-center">
              Have we sent an email?
              <div className="px-1">
                <PlainTootip
                  content="Search for companies to whom we have emailed in the past X days"
                  name="Have we sent an email"
                  height={"12px"}
                  width={"12px"}
                  isActive={communicationFilter.option === "reached_out"}
                />
              </div>
            </div>
          </span>
          <span
            className={
              option === "not_reached_out"
                ? "selected tp-button btn"
                : "tp-button btn"
            }
            onClick={() => handleCommunicationOptionChange("not_reached_out")}
          >
            <div className="d-flex communication-btn flex-align-center">
              Have we not sent an email?
              <div className="px-1">
                <PlainTootip
                  content="Search for companies to whom we have not emailed in the past X days"
                  name="Have we not sent an email"
                  height={"12px"}
                  width={"12px"}
                  isActive={communicationFilter.option === "not_reached_out"}
                />
              </div>
            </div>
          </span>
        </div>
        {option === "reached_out" || option === "not_reached_out" ? (
          <div className="not-reached-out-options">
            <Row className="mb-2">
              <span className="mb-2">Action done X days ago</span>
              <Col xs={7}>
                <Form.Control
                  type="number"
                  value={notReachedOutValue}
                  onChange={handleNotReachedOutChange}
                  min="0"
                  max={timeUnit === "months" ? "12" : ""}
                  placeholder="Enter number"
                />
              </Col>
              <Col xs={5} className="communication-dropdown">
                <Form.Select
                  as="select"
                  value={timeUnit}
                  onChange={handleTimeUnitChange}
                >
                  <option value="days">Days</option>
                  <option value="months">Months</option>
                  <option value="years">Years</option>
                </Form.Select>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    );
  };

  // Function to update year founded values
  const handleYearFoundedChange = (fieldName, value) => {
    if (/^\d*$/.test(value)) {

      // Get the current year dynamically
      const currentYear = new Date().getFullYear();

      // Adjust the value if it exceeds the current year
      if ((fieldName === 'lesserThanValue' || fieldName === 'greaterThanValue') && value > currentYear) {
        value = currentYear;
      }

      // Update the state based on the field being updated
      setYearFounded(prevState => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const renderYearFounded = () => {
    return (
      <div className="year-founded-container mt-3">
        <Row className="p-0">
          <span className="">Between</span>
          <Col xs={12} className="d-flex justify-content-center align-items-center mb-2">
            <Form.Group className="mb-0 mr-2">
              <Form.Control
                type="text"
                placeholder="Greater than"
                value={yearFounded.greaterThanValue}
                onChange={(e) => handleYearFoundedChange('greaterThanValue', e.target.value)}
              />
            </Form.Group>
            <span className="mx-2">-</span>
            <Form.Group className="mb-0">
              <Form.Control
                type="text"
                placeholder="Less than"
                value={yearFounded.lesserThanValue}
                onChange={(e) => handleYearFoundedChange('lesserThanValue', e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const handlellmchange = (value) => {
    setLLMFilters((prevState) => ({
      ...prevState,
      searchTerm: value,
      enteredValue: ""
    }));
    // setShowDropdown(false)
  };

  const handlellmKeyDownFn = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      const dropdownItem = dropdownRef.current?.querySelector(".autocomplete-item");
      dropdownItem && dropdownItem.focus();
    }
  
    if (e.key === "Enter") {
      e.preventDefault();
      if (showDropdown) {
        const focusedItem = document.activeElement;
        if (focusedItem && focusedItem.classList.contains("autocomplete-item")) {
          handleOptionSelect();
        } else {
          setLLMFilters((prevState) => ({
            ...prevState,
            searchTerm: e.target.value,
            enteredValue: e.target.value,
          }));
          setIsFiltersVisible(true);
        }
      } else {
        setLLMFilters((prevState) => ({
          ...prevState,
          searchTerm: e.target.value,
          enteredValue: e.target.value,
        }));
        setIsFiltersVisible(true);
      }
      setShowDropdown(false);
      e.target.blur();
    }
  };

  const handleClearllm = () => {
    setLLMFilters({
      enteredValue: "",
      searchTerm: ""
    })
    setFilterVisible(false);
  }

  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  const handleIsSaasToggle = (isOn) => {
    setSaasFilter((prevState) => ({
      ...prevState,
      isSaasFilterApplied: isOn,
    }));
  };

  const renderIsSaasFilter = () => {
    return (
      <div className="saas-filter-container">
        <Form.Check
          type="switch"
          id="custom-switch"
          label="Filter by Saas"
          checked={saasFilter.isSaasFilterApplied}
          onChange={(e) => handleIsSaasToggle(e.target.checked)}
        />
      </div>
    );
  };

  const checkIfFilterApplied = (filterValue) => {
    return (
      checkboxObject[filterValue]?.length > 0 ||
      (filterValue === "headquarter_search" && (hqLocation?.["countries"]?.selected?.length > 0 || hqLocation?.["states"]?.selected?.length > 0 || hqLocation?.["cities"]?.selected?.length > 0)) ||
      (filterValue === "year_founded" && (yearFounded?.greaterThanValue > 0 || yearFounded?.lesserThanValue > 0)) ||
      (filterValue === "communication_with_telescope" && (communicationFilter?.option === "reached_out" || communicationFilter?.option === "not_reached_out" || communicationFilter?.days_of_email_sent > 0)) ||
      (filterValue === "visit_history" && visitHistoryFilter?.days_of_email_sent > 0) ||
      (filterValue === "investment_data" && sliderObject["investment_data"]?.selectedTags?.some((tag) => tag.value && tag.value.trim() !== "")) ||
      (filterValue === "employee_insights" && sliderObject["employee_count"]?.selectedTags?.some((tag) => tag.value && tag.value.trim() !== "")) ||
      (filterValue === "growth" && selectedGrowthsData?.some(tag => (tag?.greaterThanValue > 0 || tag?.lessThanValue > 0))) ||
      (filterValue === "growth_tags" && selectedGrowthsTag?.length > 0) ||
      (filterValue === "founder_location_search" && selectedFounderLocation.data?.length > 0)
    );
  };

  const countAppliedFilters = () => {
    let appliedCount = 0;
    filterDisplayNames(searchTerm)?.forEach((filter) => {
      if (checkIfFilterApplied(filter.server_value)) {
        appliedCount++;
      }
    });

    return appliedCount;
  };

  const renderFilterContent = (filter) => {
    const serverValue = filter.server_value;
    const selectedValues = checkboxObject[serverValue] || [];
    const allOptions = filter.options || [];
    const arrayVal = getArrayVal(filter);

    return (
      <Form>
        <Row className='discovery-container'>
          <div className='set-filter'>
            <div className='filter-container'>
              <div className='filters-list'>
                {filterDisplayNames(searchTerm).map((item, index) => {
                  const arrayVal = getArrayVal(item);
                  return (
                    <div key={index} className={`filter-item ${activeFilter === item.server_value ? 'active' : ''}`}>
                      {activeFilter === item.server_value && (
                        <div className='filter-dropdown'>
                          {fieldsWithSearchBox.includes(item.server_value) &&
                          dropdownsearchTerms &&
                          Object.keys(dropdownsearchTerms).length && (
                            <Form.Group controlId={`accordionSearch${index}`} className='field-item'>
                              <Form.Group controlId='searchTerm'>
                                <Form.Control
                                  type='text'
                                  placeholder='Search'
                                  value={
                                    dropdownsearchTerms[item.server_value] || ''
                                  }
                                  onChange={e =>
                                    handleSearchBoxChange(
                                      item.server_value,
                                      e.target.value,
                                      arrayVal
                                    )
                                  }
                                  className='mb-3 custom-box'
                                />
                              </Form.Group>
                              {selectedValues.length > 0 && (
                                <div className="selected-filters">
                                  <div className="selected-filters-header">
                                    <b className="selected-filters-heading">Selected Filters</b>
                                    <span className="uncheck-selected-button" onClick={() => handleRemoveFilterGroup(item.server_value)}>Uncheck Selected</span>
                                  </div>
                                  {selectedValues.map((value, index) => {
                                    const ownerDetails = arrayVal.find((owner) => owner.email_address === value);
                                    const displayName = ownerDetails ? ownerDetails.full_name : value;
                                    return (
                                      <div key={index} className="filter-item selected">
                                      <Form.Check
                                        type="checkbox"
                                        checked={true}
                                        label={displayName}
                                        onChange={() =>
                                          handleCheckboxChange(ownerDetails || value, false, serverValue, allOptions)
                                        }
                                      />
                                    </div>
                                    )
                                  })}
                                  <hr className="separator-line" />
                                </div>
                              )}
                              <Form.Group controlId={`${item.server_value}Dropdown${index}`}>
                                {renderTopButtons(item.server_value)}
                                {arrayVal && arrayVal?.length > 0 && (
                                  <>
                                    <Form.Check
                                      type='checkbox'
                                      label='Select All'
                                      id={`selectAllTpOwners${index}`}
                                      onChange={e =>
                                        handleCheckboxChange(
                                          'Select All',
                                          e.target.checked,
                                          item.server_value,
                                          arrayVal
                                        )
                                      }
                                      checked={
                                        checkboxObject[`${item.server_value}`]
                                          ?.length === (arrayVal || []).length
                                      }
                                    />
                                    {arrayVal
                                      ?.filter(val => {
                                        if (item.server_value === 'tp_owners') {
                                          return val.full_name
                                            .toLowerCase()
                                            .includes(
                                              (
                                                dropdownsearchTerms[
                                                `${item.server_value}`
                                                ] || ''
                                              ).toLowerCase()
                                            )
                                        } else {
                                          return val
                                            .toLowerCase()
                                            .includes(
                                              (
                                                dropdownsearchTerms[
                                                `${item.server_value}`
                                                ] || ''
                                              ).toLowerCase()
                                            )
                                        }
                                      })
                                      ?.slice(
                                        0,
                                        checkboxObjectVisiblity[item.server_value]
                                          ?.count
                                      )
                                      ?.map((value, valIndex) => {
                                        let checked =
                                          item.server_value === 'tp_owners'
                                            ? value.email_address
                                            : value
                                              ? value
                                              : ''
                                        let name =
                                          item.server_value === 'tp_owners'
                                            ? value.full_name
                                            : value
                                              ? value
                                              : ''
                                        return (
                                          <div
                                            key={valIndex}
                                            className='d-flex align-items-center cursor-pointer'
                                          >
                                            <Form.Check
                                              type='checkbox'
                                              id={`${item.server_value}${index}_${valIndex}`}
                                              label={name}
                                              checked={checkboxObject[
                                                `${item.server_value}`
                                              ]?.includes(checked)}
                                              onChange={e =>
                                                handleCheckboxChange(
                                                  value,
                                                  e.target.checked,
                                                  item.server_value
                                                )
                                              }
                                              className='cursor-pointer'
                                            />
                                          </div>
                                        )
                                      })}
                                  </>
                                )}
                                {item.server_value === 'tp_status' && (
                                  <div className='flex-wrap mb-3'>
                                    {tpStatusOperator === 'was' && (
                                      <div className='since-days'>
                                        <span className='mb-2'>
                                          Since X days ago
                                        </span>
                                        <Form.Group className='mt-2'>
                                          <Form.Control
                                            type='text'
                                            placeholder='10'
                                            value={tpStatusSince}
                                            onChange={e =>
                                              handleTpStatusSinceChange(
                                                e.target.value
                                              )
                                            }
                                            disabled={
                                              tpStatusOperator !== 'was' ||
                                              !checkboxObject['tp_status'].length
                                            }
                                          />
                                        </Form.Group>
                                      </div>
                                    )}
                                  </div>
                                )}
                                {arrayVal.length >
                                  checkboxObjectVisiblity[item.server_value]
                                    ?.count &&
                                  checkboxObjectVisiblity[item.server_value]
                                    ?.isSearching &&
                                  checkboxObjectVisiblity[item.server_value]
                                    ?.searchCount > 20 && (
                                    <span
                                      className='custom-btn-show-more flex-wrap px-3'
                                      size='sm'
                                      onClick={() =>
                                        handleShowAll(item.server_value, arrayVal)
                                      }
                                    >
                                      {`Show All (${checkboxObjectVisiblity[item.server_value]
                                        ?.searchCount - 20
                                        })`}
                                    </span>
                                  )}
                                {arrayVal.length >
                                  checkboxObjectVisiblity[item.server_value]
                                    ?.count &&
                                  !checkboxObjectVisiblity[item.server_value]
                                    ?.isSearching && (
                                    <span
                                      className='custom-btn-show-more flex-wrap px-3'
                                      size='sm'
                                      onClick={() =>
                                        handleShowAll(item.server_value, arrayVal)
                                      }
                                    >
                                      {`Show All (${arrayVal.length - 20})`}
                                    </span>
                                  )}
                                {arrayVal.length ==
                                  checkboxObjectVisiblity[item.server_value]
                                    ?.count && (
                                    <span
                                      className='custom-btn-show-more flex-wrap px-3'
                                      size='sm'
                                      onClick={() => handlehide(item.server_value)}
                                    >
                                      {`Hide`}
                                    </span>
                                  )}
                              </Form.Group>
                            </Form.Group>
                          )}
                          {!fieldsWithSearchBox.includes(item.server_value) &&
                            item.server_value !== 'headquarter_search' &&
                            item.server_value !== 'growth' &&
                            item.server_value !== 'growth_tags' &&
                            item.server_value !== 'visit_history' &&
                            item.server_value !== 'communication_with_telescope' &&
                            item.server_value !== 'investment_data' &&
                            item.server_value !== 'total_funding_usd' &&
                            item.server_value !== 'employee_insights' &&
                            item.server_value !== 'founder_location_search' &&
                            item.server_value !== 'year_founded' && (
                              <Form.Group
                                controlId={`numericValue${index}`}
                                className='field-item'
                              >
                                <TagsInSlider
                                  onChange={e =>
                                    handleSliderTagChange(e, item?.server_value)
                                  }
                                  selectedTag={
                                    sliderObject[item.server_value]?.selectedTags ||
                                    []
                                  }
                                  type={
                                    item?.server_value === 'employee_count'
                                      ? 'numeric'
                                      : 'percentage'
                                  }
                                />
                              </Form.Group>
                            )}
                            {!fieldsWithSearchBox.includes(item.server_value) &&
                              item.server_value !== 'growth' &&
                              item.server_value !== 'growth_tags' &&
                              item.server_value !== 'visit_history' &&
                              item.server_value !== 'communication_with_telescope' &&
                              item.server_value !== 'investment_data' &&
                              item.server_value !== 'total_funding_usd' &&
                              item.server_value !== 'founder_location_search' &&
                              item.server_value !== 'headquarter_search' &&
                              item.server_value !== 'year_founded' && (
                                <div>
                                  {employeeInsightsTags.map(
                                    (employeeInsightTag, index) => {
                                      const sliderData =
                                        sliderObject[employeeInsightTag]
                                      const insightDisplayName =
                                        employeeInsightConstants?.find(
                                          item =>
                                            item.server_value === employeeInsightTag
                                        )
                                      return (
                                        <div key={index}>
                                          <div className='f-s-15 employeeInsight mt-3'>
                                            <span>
                                              {insightDisplayName
                                                ? insightDisplayName.displayName
                                                : employeeInsightTag}
                                            </span>
                                          </div>
                                          <Form.Group
                                            controlId={`numericValue${index}`}
                                            className='field-item'
                                          >
                                            <TagsInSlider
                                              onChange={e =>
                                                handleSliderTagChange(
                                                  e,
                                                  employeeInsightTag
                                                )
                                              }
                                              selectedTag={
                                                sliderData?.selectedTags || []
                                              }
                                              type={
                                                employeeInsightTag === 'employee_count'
                                                  ? 'numeric'
                                                  : 'percentage'
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                      )
                                    }
                                  )}
                                </div>
                            )}
                            {item.server_value === 'visit_history' && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className='field-item'
                              >
                                {renderVisitHistoryFilter()}
                              </Form.Group>
                            )}
                            {item.server_value === 'communication_with_telescope' && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className='field-item'
                              >
                                {renderCommunicationFilter()}
                              </Form.Group>
                            )}
                            {item.server_value === 'growth' && (
                              <Form.Group controlId={`tags${index}`} className='field-item'>
                                {renderGrowthTags()}
                              </Form.Group>
                            )}
                            {item.server_value === "growth_tags" && (
                              <Form.Group controlId={`tags${index}`} className="field-item">
                                {renderGrowthTagsFilter()}
                              </Form.Group>
                            )}
                            {item.server_value === 'investment_data' && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className='field-item'
                              >
                                {renderTotalFundingTags()}
                              </Form.Group>
                            )}
                            {item.server_value === 'founder_location_search' && (
                              <Form.Group
                                controlId={`founder-location${index}`}
                                className='field-item '
                              >
                                <div className='d-flex flex-wrap '>
                                  {render_founderLocationTags()}
                                </div>
                                <div className='founder-location'>
                                  <FounderLocation
                                    onChange={obj => handleFounderChange(obj)}
                                    locationType={founderLocationTag}
                                    filterToRemove={founderLocationChange}
                                    itemToAdd={llmFounderLocation}
                                    isAlreayDataPresent={selectedFounderLocation}
                                  />
                                </div>
                              </Form.Group>
                            )}
                            {item.server_value === 'headquarter_search' && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className='field-item'
                              >
                                {render_hq_countries()}
                              </Form.Group>
                            )}
                            {item.server_value === 'year_founded' && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className='field-item'
                              >
                                {renderYearFounded()}
                              </Form.Group>
                            )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
      </Row>
    </Form>
  )};

  const handleSortChange = (criteria) => {
    setFilterSortState((prev) => ({ ...prev, sortCriteria: criteria }));
  };

  const renderFilters = () => {
    const sortedFilters = filterDisplayNames(searchTerm).sort((a, b) => {
      const isFilterAppliedA = checkIfFilterApplied(a.server_value);
      const isFilterAppliedB = checkIfFilterApplied(b.server_value);
  
      if (isFilterAppliedA && !isFilterAppliedB) return -1;
      if (!isFilterAppliedA && isFilterAppliedB) return 1;
  
      return 0;
    });
  
    return (
      <div className="discovery-filters-container">
        <div className="discovery-filters">
          <div className="filter-names">
            {sortedFilters.map((filter) => {
              const isFilterApplied = checkIfFilterApplied(filter.server_value);
              const selectedValues = checkboxObject[filter.server_value] || [];
              const selectedCount = selectedValues.length;
  
              return (
                <div
                  key={filter.server_value}
                  data-filter={filter.server_value}
                  className={`filter-name ${activeFilter === filter.server_value ? "active" : ""}`}
                  onClick={() => setActiveFilter(filter.server_value)}
                >
                  {filter.display_name}
                  {selectedCount > 0 && (
                    <span className="selected-count">{selectedCount}</span>
                  )}
                  {isFilterApplied && (
                    <>
                      <span
                        className={isLoading ? "remove-filter disabled" : "remove-filter"}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent parent click handler
                          handleRemoveFilterGroup(filter.server_value);
                        }}
                      >
                        <DustbinIcon className="remove-filter-icon" />
                      </span>
                      <span className="filter-dot" />
                    </>
                  )}
                </div>
              );
            })}
          </div>
          <div className="filter-content">
            {filterDisplayNames(searchTerm)
              .filter((filter) => filter.server_value === activeFilter)
              .map((filter) => (
                <div key={filter.server_value}>{renderFilterContent(filter)}</div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const handleFocus = () => {
    setShowDropdown(true);
  };
  const handleOptionSelect = () => {
    setLLMFilters({
      searchTerm: "Show all companies",
      enteredValue: "Show all companies",
    });
    setShowDropdown(false);
  };

  const handleBlur = (e) => {
  };

  const handleSearchHistoryCallback = (type, val) => {
    const updateSliderObject = (field, numericValue, isGreaterThan) => {
      setSliderObject((prevState) => {
        const updatedState = { ...prevState };
        const fieldData = { ...updatedState[field] };
  
        // Remove matching range conditions
        fieldData.selectedTags = fieldData.selectedTags.filter((tag) => {
          if (isGreaterThan && tag.operator === "greaterThan" && tag.value === numericValue) {
            return false;
          }
          if (!isGreaterThan && tag.operator === "lessThan" && tag.value === numericValue) {
            return false;
          }
          return true;
        });
  
        // Update greaterThan or lessThan values
        if (isGreaterThan && fieldData.greaterThanValue === numericValue) {
          fieldData.greaterThanValue = "";
        } else if (!isGreaterThan && fieldData.lessThanValue === numericValue) {
          fieldData.lessThanValue = "";
        }
  
        updatedState[field] = fieldData;
        return updatedState;
      });
    };
  
    const updateGrowthData = (val) => {
      const [period, condition] = val.split(": ");
      const value = `growth_${period}`;
      const isGreaterThan = condition.includes(">=");
      const numericValue = condition.replace(">=", "").replace("<=", "").replace("%", "").trim();
  
      setSelectedGrowthsData((prevState) =>
        prevState.map((item) => {
          if (item.value === value) {
            const updatedItem = { ...item };
            if (isGreaterThan && updatedItem.greaterThanValue === numericValue) {
              delete updatedItem.greaterThanValue;
            } else if (!isGreaterThan && updatedItem.lessThanValue === numericValue) {
              delete updatedItem.lessThanValue;
            }
            return updatedItem;
          }
          return item;
        })
      );
    };
  
    const updateFounderLocation = (type, val) => {
      setSelectedFounderLocation((prevState) => {
        const updatedData = prevState.data.filter((item) => item !== val.trim());
        return {
          ...prevState,
          type,
          data: updatedData,
        };
      });
      setFounderLocationChange(val.trim());
    };
    
    if (type === "TP Owner") {
      const value = tpOwners?.find((owner) => owner.email_address === val);
      handleCheckboxChange(value, false, "tp_owners", []);
    } else if (type === "TP Status") {
      handleCheckboxChange(val, false, "tp_status", []);
    } else if (type === "Status") {
      handleCheckboxChange(val, false, "status", []);
    } else if (type === "Growth") {
      updateGrowthData(val);
    } else if (type === "Growth Tags") {
      const value = selectedGrowthsTag.filter((item) => item.displayName?.trim() !== val?.trim());
      setSelectedGrowthsTag(value);
    } else if (type === "Year Founded") {
      const isGreaterThan = val.includes(">=");
      const numericValue = val.replace(">=", "").replace("<=", "").trim();
  
      setYearFounded((prevState) => {
        const updatedYear = { ...prevState };
  
        if (isGreaterThan && updatedYear.greaterThanValue === numericValue) {
          updatedYear.greaterThanValue = "";
        } else if (!isGreaterThan && updatedYear.lesserThanValue === numericValue) {
          updatedYear.lesserThanValue = "";
        }
        return updatedYear;
      });
    } else if (type === "Employee Insights") {
      const isRange = val.includes(">=") || val.includes("<=");
      if (isRange) {
        const isGreaterThan = val.includes(">=");
        const numericValue = val.replace(">=", "").replace("<=", "").trim();
        updateSliderObject("employee_count", numericValue, isGreaterThan);
      } else {
        setSliderObject((prevState) => {
          const updatedState = { ...prevState };
          const employeeCount = { ...updatedState.employee_count };
  
          employeeCount.selectedTags = employeeCount.selectedTags.filter(
            (tag) =>
              tag.displayName.trim().replace(/\s/g, "") !==
              val.trim().replace(/\s/g, "")
          );
  
          updatedState.employee_count = employeeCount;
          return updatedState;
        });
      }
    } else if (type === "Investment Data") {
      const isRange = val.includes(">=") || val.includes("<=");
      if (isRange) {
        const isGreaterThan = val.includes(">=");
        const numericValue = val.replace(">=", "").replace("<=", "").trim();
        updateSliderObject("investment_data", numericValue, isGreaterThan);
      }
    } else if (type === "Visit History") {
      setVisitHistoryFilter((prevState) => ({
        ...prevState,
        days_of_email_sent: "",
        visitHistoryValue: ""
      }));
    } else if (type === "Communication with Telescope") {
      if (val === "Reached Out" || val === "Not Reached Out") {
        setCommunicationFilter((prevState) => ({
          ...prevState,
          option: "",
          reachedOut: false,
          notReachedOutValue: "",
          days_of_email_sent: 0,
        }));
      } else {
        setCommunicationFilter((prevState) => ({
          ...prevState,
          days_of_email_sent: "",
          notReachedOutValue: ""
        }));
      }
    } else if (type === "Founder Countries" || type === "Founder States" || type === "Founder Cities") {
      const normalizedType = type.replace("Founder ", "").toLowerCase();
      updateFounderLocation(normalizedType, val);
    } else  if (type === "Headquarter States") {
      setHqLocation((prevState) => {
        const updatedStates = prevState.states.selected.filter(state => state !== val);
        return {
          ...prevState,
          states: {
            ...prevState.states,
            selected: updatedStates
          }
        };
      });
    } else if (type === "Headquarter Countries") {
      setHqLocation((prevState) => {
        const updatedCountries = prevState.countries.selected.filter(country => country !== val);
        return {
          ...prevState,
          countries: {
            ...prevState.countries,
            selected: updatedCountries
          }
        };
      });
    } else if (type === "Headquarter Cities") {
      setHqLocation((prevState) => {
        const updatedCities = prevState.cities.selected.filter(city => city !== val);
        return {
          ...prevState,
          cities: {
            ...prevState.cities,
            selected: updatedCities
          }
        };
      });
    } else if (type === "Filter by Saas") {
      setSaasFilter((prevState) => ({
        ...prevState,
        isSaasFilterApplied: false,
      }));
    }
  };

  return (
    <>
      {historySlider && (
        <SearchHistorySlider
          isOpen={historySlider}
          onClose={handleCloseHistorySlider}
          searchHistory={searchHistory}
          searchFilterApplied={searchFilterApplied}
          callbackFn={handleSearchHistoryCallback}
        />
      )}
      <div className="discovery-container">
        <div className={isCollapsed ? "explore-header-collapsed" : "explore-header"}>
          <span className="title">Explore</span>
          <div onClick={toggleCollapse} className="collapse-header cursor-pointer">
            {isCollapsed ? <ExpandIcon /> : <CollapseIcon />}
          </div>
        </div>
        {!isCollapsed && (
          <>
            <div className="subtitle mb-2">Choose Orion or List to view companies from them and filter further as needed.</div>
            <div className="d-flex mb-3">
              <span className={isActiveBtn === "orion" ? "toggle-btn active" : 'toggle-btn'} >Orion</span>
              {/* <span className={isActiveBtn === "list" ? "toggle-btn active" : "toggle-btn"}>List</span> */}
            </div>
            <div className="llm-search mb-4">
              <Form.Group controlId="searchTerm" className="search-group">
                <div className="input-container">
                  <SearchIcon className="search-icon" />
                  <Form.Control
                    type="text"
                    placeholder="Search or pick from frequently asked questions"
                    value={llmFilter?.searchTerm}
                    onChange={(e) => handlellmchange(e.target.value)}
                    onKeyDown={handlellmKeyDownFn}
                    className="top-search-box"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    autoComplete="off"
                    ref={inputRef}
                  />
                  <CrossIcon className="cross-icon" onClick={handleClearllm} />
                </div>
                {showDropdown && (
                  <ul
                    className="autocomplete-dropdown"
                    ref={dropdownRef}
                  >
                    <li
                      className="autocomplete-item"
                      style={{
                        display: "block",
                        padding: "8px 12px",
                        cursor: "pointer",
                      }}
                      onClick={handleOptionSelect}
                      tabIndex={0}
                      onKeyDown={(e) => e.key === "Enter" && handleOptionSelect()}
                    >
                      Show all companies
                    </li>
                  </ul>
                )}
              </Form.Group>
            </div>
          </>
        )}
        {
          llmFilter?.searchTerm || filtersApplied ? (
            <div className="companies-wrapper mb-2">
                  <div>
                    <div className="companies-count mb-1"> <b>Companies ({discoveryCompaniesResults?.total_count ? discoveryCompaniesResults?.total_count : '--'})</b></div>
                    <div className="companies-selected">
                      {
                        checkLength() ? <div className="selected-filters-container mb-2"><span className="selected-filter-category">Selected filter category :</span> {countAppliedFilters() > 0 && (<span className="selected-filters-count">{countAppliedFilters()}</span>)}</div> : null
                      }
                      <div className="">
                        {checkLength() ? (
                          <div className="flex-wrap internal-container">
                            {renderAppliedFilters()}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center justify-content-end g-2">
                    <div className="is-saas-filter">{renderIsSaasFilter()}</div>
                    <div className="filters-container">
                      <span className="filters-toggle-btn" onClick={toggleFilters} ref={toggleBtnRef}>
                        <FiltersIcon className="filters-icon" /> Filters {countAppliedFilters() > 0 && (<span className="selected-filters-count">{countAppliedFilters()}</span>)}
                      </span>
                      {isFiltersVisible && (
                        <div className="discovery-filters-visible" ref={filtersRef}>
                          <div className="arrow-up"></div>
                          {renderFilters()}
                        </div>
                        )}
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle className="custom-dropdown-button"><SortByIcon /> Sort by</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleSortChange("Company Name")}>
                              Company Name
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSortChange("TP Status")}>
                              TP Status
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSortChange("TP Owners")}>
                              TP Owners
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                  </div>
                </div>
          ) : null
        }
        <div>
          {filtersApplied || isLoading  ? (
           isLoading ? (
              <div className={isFiltersVisible ? "loader-content-left" : "loader-content"}>
                <span className="stars-icon"><StarsIcon/></span>
                <span className="moon-icon"><MoonIcon/></span>
                <span className="telescope-icon"><TelescopeSearchIcon/></span>
                <div className="earth-loader-block"><img className="earth-loader-gif" height='90px' width='380px' src={earthLoader} alt="spinner" /></div>
                <div className="loading-text">
                  Kindly wait, we are filtering companies based on
                  your requirement...
                </div>
              </div>
            ) : error ? (
              <CommonError errorMessage={error} isBlurredRequired={true} />
            ) : hasResults ? (
              <>
                <DiscoveryCards isFilterCollapsed={!filterVisible} appliedFilterString={appliedFilterString} filterSortState={filterSortState} isHeaderCollapsed={isCollapsed} />
              </>
            ) : (
              <div className="no-data-message mt-5">No data to preview!</div>
            )
          ) : (!hasResults && !showDropdown && llmFilter?.searchTerm?.trim() === 'Show all companies') ?(
            <>
              <div className={isFiltersVisible ? 'prompt-icon-left-container' : 'prompt-icon-container'}>
                <PromptIcon className='prompt-icon-display' />
              </div>
            </>
        ) : ''}
        </div>
      </div>
    </>
  );
};

export default Discovery;
