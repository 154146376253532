import Highcharts from 'highcharts';
import Highmaps from 'highcharts/highmaps';
import mapModule from 'highcharts/modules/map';
import accessibilityModule from 'highcharts/modules/accessibility';

// Initialize the Highcharts modules
accessibilityModule(Highcharts);
mapModule(Highcharts);


function formatNumber(number) {
    if (number === "n/a") {
      return "n/a";
    }
    if (number >= 1000000000) {
      return (number / 1000000000)?.toFixed(2) + "B";
    } else if (number >= 1000000) {
      return (number / 1000000)?.toFixed(2) + "M";
    } else if (number >= 1000) {
      return (number / 1000)?.toFixed(2) + "K";
    } else {
      return number?.toString();
    }
  }

// Function to generate a bar graph
export function generateBarGraph(container, data, onBarClick) {
    if (!container) {
        console.error(`Container not found`);
        return;
    }

    let chartHeight = '240px';
    if (data.length <= 2) {
        chartHeight = '160px';
    } else if (data.length <= 4) {
        chartHeight = '200px';
    }

    let selectedBarIndex = 0;

    const chartOptions = {
        chart: {
            type: 'bar',
            height: chartHeight,
            width: 530,
            marginLeft: 150,
            backgroundColor: 'transparent',
        },
        title: { text: '' },
        yAxis: {
            title: { text: null },
            labels: { enabled: false },
            gridLineWidth: 1,
            gridLineColor: '#D7D0FFB2',
            tickAmount: 9,
            lineWidth: 1,
        },
        xAxis: {
            categories: data.map(d => d.name),
            title: { text: null },
            lineWidth: 1,
            gridLineWidth: 0,
            labels: {
                style: {
                    fontFamily: 'Avenir-Regular',
                    fontSize: '14px',
                    color: '#fff',
                },
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    formatter: function () {
                        const point = this;
                        return `<div style="display: flex; justify-content: space-between; width: 100%;">
                                    ${point?.point?.count && point?.point?.count !== "n/a" ? `<span style="color: #fff; font-family: 'Avenir-Regular'; font-size: 13px; font-weight: 500; margin-right:4px ">${formatNumber(point?.point?.count) + " "} </span>` : ''}
                                    <span style="color: #fff; font-family: 'Avenir-Regular'; font-size: 13px; font-weight: 500;"> (${point.y}%)</span>
                                </div>`;
                    },
                    align: 'left',
                    verticalAlign: 'middle',
                    x: 8,
                },
                pointWidth: 17,
                groupPadding: 0.2,
                borderRadius: [0, 4, 4, 0],
                showInLegend: false,
                states: {
                    hover: { color: 'rgb(25, 183, 251)' },
                },
                events: {
                    click: function (event) {
                        const selectedBarName = event?.point?.options?.name;
                        onBarClick(selectedBarName);
                        selectedBarIndex = event.point.index;
                        chart.series[0].update({
                            data: data.map((item, index) => ({
                                ...item,
                                color: index === selectedBarIndex ? 'rgb(170,170,170)' : 'rgb(220, 220, 220)',
                            })),
                        });
                    },
                },
            },
        },
        tooltip: false,
        series: [{ data: data.map((item, index) => ({ ...item, color: index === selectedBarIndex ? 'rgb(170,170,170)' : 'rgb(220, 220, 220)' })), colorByPoint: true }],
        credits: { enabled: false },
    };

    const chart = Highcharts.chart(container, chartOptions);
}


// Function to generate a line graph
export function generateLineGraph(container, data) {
    // Ensure that the container exists
    if (!container) {
        console.error(`Container not found`);
        return;
    }
    
    // Determine if the new theme is enabled
    const isNewThemeEnabled = false; // Set your condition here

    // Extracting series data for raw_count, raw_inflow, and raw_outflow
    const seriesDataCount = data.map(entry => ({
        x: new Date(entry.metric_recorded_on).getTime(),
        y: entry.raw_count,
    }));

    const seriesDataInflow = data.map(entry => ({
        x: new Date(entry.metric_recorded_on).getTime(),
        y: entry.raw_inflow,
    }));

    const seriesDataOutflow = data.map(entry => ({
        x: new Date(entry.metric_recorded_on).getTime(),
        y: entry.raw_outflow,
    }));

    // Compute min and max for yAxis
    const allValues = seriesDataCount
        .concat(seriesDataInflow)
        .concat(seriesDataOutflow)
        .map(point => point.y);

    const max = Math.max(...allValues);
    const min = Math.min(...allValues);

    const lineChartOptions = {
        chart: {
            type: 'line',
            height: '240px',
            backgroundColor: 'transparent',
            zoomType: 'x', // Added zoomType for consistency with plugin extension
        },
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: '',
                style: {
                    color:  "#fff" 
                },
            },
            labels: {
                style: {
                    color:  "#fff" 
                },
            },
            events: {
                afterSetExtremes: function (event) {
                    if (event.trigger === 'rangeSelectorButton') {
                        const xAxis = this; // Reference to xAxis
                        const options = xAxis.options;

                        if (event.min === event.max) { // If zoomed to a single year
                            options.labels = { format: '{value:%Y}' }; // Update labels to display year
                        } else { // If zoomed out or zoomed to multiple years
                            options.labels = { format: '{value:%b %Y}' }; // Update labels to display month and year
                        }

                        xAxis.update(options); // Update xAxis options
                    }
                }
            }
        },
        yAxis: {
            title: {
                text: '',
                style: {
                    color:"#fff" 
                },
            },
            labels: {
                style: {
                    color: "#fff" 
                },
            },
            min: min, // Set min value of y-axis
            max: max, // Set max value of y-axis
        },
        legend: {
            itemStyle: {
                color:  "#fff" // Legend text color
            }
        },
        series: [
            {
                name: 'Total Population',
                data: seriesDataCount,
                color: "#19B7FB",
            },
            {
                name: 'Employee Joined',
                data: seriesDataInflow,
                color: "#108C4A",
            },
            {
                name: 'Employee Left',
                data: seriesDataOutflow,
                color: "#7778D0",
            }
        ],
        rangeSelector: {
            selected: 1, // Show data for 1 year by default
            buttons: [{
                type: 'year',
                count: 1,
                text: '1y'
            }, {
                type: 'all',
                text: 'All'
            }]
        }
    };

    Highcharts.chart(container, lineChartOptions);
}
