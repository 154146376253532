import React, { useEffect, useState, useRef, useCallback } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Token, Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";

import { getHQLookup } from "../../../store/actions";
import { getAddress } from "../../../utils";

import "./founderLocation.scss"

const FounderLocation = ({ onChange, locationType, filterToRemove, itemToAdd, isAlreayDataPresent }) => {
    const asyncTypeaheadRef = useRef(null);

    const [option, setOption] = useState([]);
    const [selectedFounderLocationObject, setSelectedFounderLocationObject] = useState({
        isHidden: true,
        selected: [],
        type: locationType?.serverValue
    });

    useEffect(() => {
        if(isAlreayDataPresent && Object.keys(isAlreayDataPresent)?.length && isAlreayDataPresent?.data?.length) {
            if (!isAlreayDataPresent?.data.every((element) => selectedFounderLocationObject.selected.includes(element))) {
                setSelectedFounderLocationObject({
                ...selectedFounderLocationObject,
                selected: isAlreayDataPresent?.data,
                type: isAlreayDataPresent.type
              });
            }
        }
    },[isAlreayDataPresent])

    const [countries, states, cities] = useSelector(({ discoveryReducer }) => [
        discoveryReducer.metadata.countries,
        discoveryReducer.metadata.states,
        discoveryReducer.metadata.cities,
    ]);
    
    useEffect(() => {
        if(filterToRemove) {
            setSelectedFounderLocationObject((prevSelected) => {
                let formObj = { ...prevSelected };
                // Ensure that formObj[itemToRemove.type] is an object before accessing its properties
                if ( formObj.selected?.length) {
                  const updatedSelected =  filterToRemove === "all" ? [] : formObj.selected.filter(
                    (item) => item !== filterToRemove
                  );
                  formObj = {
                    ...formObj,
                    selected: updatedSelected,
                  };
                }
                return formObj;
              });
        }
    }, [filterToRemove])

    useEffect(() => {
        if(Object.keys(itemToAdd)?.length) {
            if (!itemToAdd?.val.every((element) => selectedFounderLocationObject.selected.includes(element))) {
                setSelectedFounderLocationObject({
                ...selectedFounderLocationObject,
                selected: itemToAdd?.val,
                type: itemToAdd.type
              });
              onChange(itemToAdd?.val)
            }
        }
    }, [itemToAdd])

    useEffect(() => {
        if(locationType) {
            let options = locationType?.serverValue === 'countries' && countries?.length ? [...countries] : locationType?.serverValue === 'states' && states?.length  ? [...states] : locationType?.serverValue === 'cities' && cities?.length  ? [...cities] : []
            setOption(options)
        }
    },[locationType])

    useEffect(() => {
        if(locationType) {
            let options = locationType?.serverValue === 'countries' && countries?.length ? [...countries] : locationType?.serverValue === 'states' && states?.length  ? [...states] : locationType?.serverValue === 'cities' && cities?.length  ? [...cities] : []
            setOption(options)
        }
    },[countries])


    useEffect(()=> {
        if (!selectedFounderLocationObject?.isHidden) {
            setTimeout(() => {
                asyncTypeaheadRef?.current?.focus();
            }, 0);
        }
    },[selectedFounderLocationObject?.isHidden])

    const handlePlus = () => {
        setSelectedFounderLocationObject({
            ...selectedFounderLocationObject,
            isHidden: false
        });
    };
    const handleBlur = () => {
        setSelectedFounderLocationObject({
            ...selectedFounderLocationObject,
            isHidden: true
        });
    };

    function handleChange(val) {
        if(locationType.serverValue === selectedFounderLocationObject?.type) {
            if (!val.every((element) => selectedFounderLocationObject.selected.includes(element))) {
                setSelectedFounderLocationObject({
                ...selectedFounderLocationObject,
                selected: val,
                type: locationType.serverValue
              });
              onChange(val)
            }
        } else {
            let filtered = val.filter((element) => !selectedFounderLocationObject.selected.includes(element))
                setSelectedFounderLocationObject({
                ...selectedFounderLocationObject,
                selected: filtered,
                type: locationType.serverValue
              });
              onChange(filtered)
        }
    }

    const handleRemove = (itemToRemove) => {
        setSelectedFounderLocationObject((prevSelected) => {
          let formObj = { ...prevSelected };
          // Ensure that formObj[itemToRemove.type] is an object before accessing its properties
          if ( formObj.selected?.length) {
            const updatedSelected = formObj.selected.filter(
              (item) => item !== itemToRemove
            );
            formObj = {
              ...formObj,
              selected: updatedSelected,
            };
            onChange(updatedSelected)
          }
          return formObj;
        });
    };

    const renderComponent = () => {
        return (
            <Form.Group
                className="mb-3 m-r-5 "
                controlId="formBasicEmail"
            >
                <div className="mb-1">Select {locationType?.displayName}</div>
                <div className="d-flex flex-wrap">
                    <div
                        className="plusBtn d-flex justify-content-center align-items-center"
                      onClick={() => handlePlus()}
                    >
                        {" "}
                        +{" "}
                    </div>
                    {selectedFounderLocationObject?.selected?.length && selectedFounderLocationObject?.type === locationType?.serverValue
                        ? selectedFounderLocationObject?.selected?.map((item, index) => (
                            <span
                                key={index}
                                className="selectedItem  d-flex justify-content-between align-items-center"
                            >
                                {item}{" "}
                                <span
                                    className="closeBtn"
                                  onClick={() => handleRemove(item)}
                                >
                                    {" "}
                                    x{" "}
                                </span>
                            </span>
                        ))
                        : null}
                    {!selectedFounderLocationObject?.isHidden ? (
                        <Typeahead
                            ref={asyncTypeaheadRef}
                            id={`founder-id`}
                            multiple
                            flip={true}
                            onBlur={() => handleBlur()}
                            clearButton={false}
                            options={option || []}
                            onChange={(val) => handleChange(val)}
                            selected={selectedFounderLocationObject?.selected}
                            placeholder={`Select `}
                            className="mb-3 hq-typeahead-dropdown"
                            renderToken={(option, props, idx) => (
                                <Token className={idx > 0 ? "hidden" : "hidden"}>
                                    <></>
                                </Token>
                            )}
                            filterBy={(option, props) => {
                                const searchTerm = props.text.toLowerCase();
                                return option.toLowerCase().includes(searchTerm);
                            }}
                            maxResults={15}
                            renderMenuItemChildren={(option, props) => (
                                <div>{option}</div>
                            )}
                        />
                    ) : null}
                </div>
            </Form.Group>
        );
    }
    return (
        <div>
            {Object.keys(locationType)?.length ? renderComponent() : null}
        </div>
    );
}

export default FounderLocation;