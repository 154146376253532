import React, { useEffect, useState } from "react";
import { ReactComponent as SuccessSvg } from "../../../assets/images/icon_svg/view-investor.svg";
import { ReactComponent as FailedSvg } from "../../../assets/images/icon_svg/failedStatus.svg";
import { Navigationbar } from "../../../commonComponents/Navbar";
import "./discoverywatch.scss";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setWatchDiscoveryItem, setUrlDiscoveryParams } from "../../../store/actions";
import { axiosDiscoveryWatch } from "../../../config/appConfig/axiosInstance";
import { LoadingBlock } from "../../../commonComponents/LoadingAnimation/LoaderComponents";

const DiscoveryWatch = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [page, setPage] = useState({
        errorStatus: false,
        message: '',
        isLoading: false
    });
    const [params, setParams] = useState({
        uuid: searchParams.get("uuid"),
        watched: searchParams.get("watched")
    });

    useEffect(() => {
        if (params?.uuid && params?.watched) {
            setPage({
                ...page, 
                isLoading: true
            });
            dispatch(setUrlDiscoveryParams(params?.uuid, params?.watched));
            dispatch(setWatchDiscoveryItem(axiosDiscoveryWatch, { uuid: params?.uuid, watched: params?.watched }))
                .then(response => {
                    if (response.status === 'success') {
                        setPage({
                            ...page, 
                            errorStatus: false,
                            message: "Discovery status 'set as watched' removed successfully!",
                            isLoading: false
                        });
                    } else {
                        setPage({
                            ...page, 
                            errorStatus: true,
                            message: response?.errorMessage,
                            isLoading: false
                        });
                    }
                });
        }
    }, [searchParams, dispatch, params?.uuid, params?.watched]);

    const navigateDiscovery = () => {
        navigate(`/explore`);
    };

    return (
        <>
            <Navigationbar />
            {page.isLoading ? <div className="p-20">
                <LoadingBlock height="130px" width="auto" className="mb-3 " />
                <LoadingBlock height="130px" width="auto" className="mb-3 " />
                <LoadingBlock height="130px" width="auto" className="mb-3 " />
                <LoadingBlock height="130px" width="auto" className="mb-3 " />
            </div>
            :
                <div className="discovery-watch-container h-100 d-flex align-items-center justify-content-center">
                    <div className="discovery-watch-content text-center">
                        {page.errorStatus ? <FailedSvg style={{ marginLeft: "6px", marginRight: "-2px" }} /> : <SuccessSvg style={{ marginLeft: "6px", marginRight: "-2px" }} />}
                        <h2 className="discovery-updated">{page.errorStatus ? 'Discovery Update Failed!' : 'Discovery Watched Status Updated!'}</h2>
                        <p className="status-updated">{page.message}</p>
                        <button className="view-discovery-button btn btn-primary" onClick={navigateDiscovery}>View Discovery</button>
                    </div>
                </div>
            }
        </>
    );
};

export default DiscoveryWatch;
