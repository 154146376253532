import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'

import References from "../References/ReferencesNew";
import ParentContainer  from '../ParentContainer/ParentContainer';
import Login from "../Login/Login"

import Submissions from '../Submissions/Submissions';
import Workboard from '../Workboard/Workboard'
import MyWorkboard from "../Workboard/MyWorkboards/MyWorkboard"
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import PublicRoutes from "../PublicRoutes/PublicRoutes";
import Discovery from '../Discovery/Discovery';
import MentionReference from '../Mentions/MentionReferences';
import Reminders from '../Reminders/Reminders';
import DiscoveryWatch from '../Discovery/DiscoveryWatch/DiscoveryWatch';
import InvestorReminders from '../InvestorReminder/InvestorReminder';
import Notes from '../Notes/Notes';
import NotesDetails from '../Notes/NotesDetails/NotesDetails';

const MainRoutes = () => (
    <Routes>

        {/** Protected Routes */}
        {/** Wrap all authenticated Route under ProtectedRoutes element */}
        <Route path="/" element={<ProtectedRoute />}>
            <Route path="/" element={<ParentContainer />}>
                <Route path="/" element={<Navigate replace to="explore" />} />
                <Route path="conversations" element={<References />} />
                <Route path="explore" element={<Discovery />} />
                <Route path="submissions" element={<Submissions />} />
                {/* <Route path="workboard" element={<Workboard />}>
                    <Route path=":id" element={<MyWorkboard />} />
                </Route> */}
                 {/* Mentions Redirect URLs */}
                 <Route path="references" element={<MentionReference />} />
                 <Route path="investorReminders" element={<InvestorReminders />} />
                 <Route path="fathom" element={<Notes />}> 
                    <Route path=":id" element={<NotesDetails />} />
                 </Route>
                <Route path="*" element={<Navigate to="/explore" />} />
            </Route>
            <Route path="reminders" element={<Reminders />} />
            <Route path="set_top_discovery_queries" element={<DiscoveryWatch />} />
        </Route> 

        {/** Public Routes */}
        {/** Wrap all public Route under PublicRoutes element */}
        <Route path="login" element={<PublicRoutes />}>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
        </Route>
    </Routes>
)

export default MainRoutes